import IconButton from '@material-ui/core/IconButton';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import FileCopyIcon from '@material-ui/icons/FileCopy';
import React from 'react';
import CopyToClipboard from '../../../../../../../components/CopyToClipboard/CopyToClipboard';

const useStyles = makeStyles({
  root: {
    width: '100%',
    maxWidth: 500,
  },
});


export default function PaymentOptionsScreen() {
    const classes = useStyles();
  return (
    <div className={classes.root}>
    <Typography variant="button" display="block" gutterBottom>
      Please make payments to:
    </Typography>
    <Typography variant="overline" style={{display: 'inline-block'}}>
      Ecosmart New Zealand Limited <br/>
      BANK OF NEW ZEALAND (BNZ)      
    </Typography>
    
    <Typography variant="overline" display="block" style={{fontSize:15}}>
    ACC NO:  02-0412-0157848-000
    <CopyToClipboard>
     {({ copy }) => (
        <IconButton 
        color="primary" 
        aria-label="copy bank account number"
        onClick={() => copy("02-0412-0157848-000")}
        >
        <FileCopyIcon />
      </IconButton>     
    )}    
    </CopyToClipboard>
    </Typography>
    <Typography variant="button" display="block" >
      
    </Typography>
   
  </div>
  );
}
