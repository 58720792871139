import { initializeApp } from '@cuba-platform/rest';
import { createMuiTheme, ThemeProvider } from '@material-ui/core/styles';
import { startRouter } from 'mobx-router';
import React from 'react';
import ReactDOM from 'react-dom';
import { App } from './App';
import { CUBA_APP_URL, REST_CLIENT_ID, REST_CLIENT_SECRET } from './config';
import { StoreProvider } from "./context/store-context";
import routes from './routing/routes';
import * as serviceWorkerRegistration from './serviceWorkerRegistration';
import store from './store/store';


//d30cbf pink

const theme = createMuiTheme({
  palette: {
    primary: {
      light: '#f46835',
      main: '#E3470E',
      dark: '#099717',
      contrastText: '#fff',
    },
      secondary: {
        light: '#5393ff',
        main: '#0EA9E1',
        dark: '#1c54b2',
        contrastText: '#fff',
      }

   /* 
    },
    secondary: {
      light: '#dd82d3',
      main: '#40E10E',
      dark: '#9e008b',
      contrastText: '#000',
    }, */
  },
});

export const cubaREST = initializeApp({
  name: "billing",
  apiUrl: CUBA_APP_URL,
  restClientId: REST_CLIENT_ID,
  restClientSecret: REST_CLIENT_SECRET,
  storage: window.localStorage,
  defaultLocale: "en"
});

store.app.setCubaRest(cubaREST)

cubaREST.onTokenExpiry(()=>{
  console.log("Token expired, attempting refresh")
  store.app.refreshToken()
})


startRouter(routes, store, {
  html5history: true, // or false if you want to use hash based routing
});


ReactDOM.render(
  <StoreProvider value={store}>
    <ThemeProvider theme={theme}>      
      <App store={store}/>      
    </ThemeProvider>
  </StoreProvider>, document.getElementById('root') 
  
)

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
serviceWorkerRegistration.register();