import { observer } from 'mobx-react-lite';
import React, { useContext, useEffect } from 'react';
import { Legend, Line, LineChart, ResponsiveContainer, Tooltip, XAxis, YAxis } from 'recharts';
import { StoreContext } from '../../../../../../../../context/store-context';
import { ConvertPeriod } from '../../../../../../../../util/utility';



const DailyChart = observer(() => {    
    const store = useContext(StoreContext);


    let effectVariable = store.app.pricesStore?.pricesDate

    useEffect(() => {
        store.app.pricesStore.updateFinalPricesForDate(store.app.pricesStore.pricesDate!)
    }, [store.app.pricesStore, effectVariable]);

    return (

        <ResponsiveContainer width="100%" height={350}  >

            <LineChart /* barCategoryGap='30%' */ height={400}
                data={store.app.pricesStore.finalPricesSet?.finalPrices}
                margin={{ top: 10, right: 0, left: 0, bottom: 0 }}
            >
                <defs>
                    <linearGradient id="colorUv" x1="0%" y1="0%" x2="0%" y2="100%">
                        <stop offset="0%" stopColor="red" />
                        <stop offset="50%" stopColor="orange" />
                        <stop offset="100%" stopColor="#53e206" />
                    </linearGradient>
                </defs>
                <XAxis
                    dataKey={'period'}
                    type='number'
                    axisLine={true}
                    tickCount={24}
                    domain={[1, 48]}
                    padding={{ left: 15, right: 15 }}
                    allowDataOverflow={false}
                    tickFormatter={(tick) => ConvertPeriod(tick)}
                />
                <YAxis orientation={'right'} type={'number'} width={30} />

                <Tooltip
                    labelFormatter={(label) => 'Time period: ' + ConvertPeriod(label as number)}
                    formatter={(price) => (price as number).toFixed(2) + 'c per kwh'}
                />

                <Legend />
                <Line
                    type="monotone"
                    key={'price'}
                    dataKey={'price'}
                    strokeWidth={8}
                    legendType="rect"
                    name={'Price'}
                    fill="url(#colorUv)"
                    stroke="url(#colorUv)"
                    color={'black'}
                    dot={false}
                >
                </Line>
            </LineChart>
        </ResponsiveContainer>
    )
})

export default DailyChart