import { CubaApp } from '@cuba-platform/rest';
import { AlertProps } from '@material-ui/lab/Alert';
import { action, computed, extendObservable, observable } from 'mobx';
import { cubaREST } from '../..';
import { Account } from '../../cuba/entities/billing_Account';
import routes from '../../routing/routes';
import store from '../store';
import AccountStore from './account/account-store';
import ContractStore from './contract/contract-store';
import IcpStore from './icp/icp-store';
import InvoiceStore from './invoice/invoice-store';
import PricesStore from './prices/prices-store';
import SignupStore from './signup/signup-store';

class AppStore {

  public title?: string;
  public user?: any;
  public cubaRest?: CubaApp

  @observable
  resetToken?: string | null 

  @observable
  snackbarOpen?: boolean = false

  @observable
  loggingIn: boolean = false
  
  private promiseAccount?: Promise<Account> 

  public accountStore: AccountStore
  public contractStore: ContractStore
  public icpStore: IcpStore
  public invoiceStore: InvoiceStore
  public pricesStore: PricesStore
  public signupStore: SignupStore
  refreshTimeout: any = undefined


  public snackBarAlert: { alertMessage: string, severity: AlertProps["severity"] } | undefined

  private connectionErrorCount: number


  constructor() {    
    this.accountStore = new AccountStore(this)
    this.contractStore = new ContractStore(this)
    this.icpStore = new IcpStore(this)
    this.invoiceStore = new InvoiceStore(this)
    this.pricesStore = new PricesStore(this)
    this.signupStore = new SignupStore(this)
    this.connectionErrorCount = 0

    //not sure if this is needed?
    extendObservable(this, {
      title: 'Ecosmart APP',
      user: undefined,
      cubaREST: CubaApp,
      account: null,
      accountBalance: computed
    });
  }



  setSnackbarState = action((open: boolean) => {
    this.snackbarOpen = open
  });

  publishSnackbarAlert = action((alertMessage: string, severity: AlertProps["severity"]) => {
    this.snackBarAlert = { alertMessage, severity }
    this.setSnackbarState(true)
  });


  setTitle = action((title: string | undefined) => {
    this.title = title;
  });

  setCubaRest = action((cubaRest: CubaApp | undefined) => {
    this.cubaRest = cubaRest;
  });  

  handleConnectionError = action((source:String, error:any) => {
  
   console.log(error.response?.status)

   if([401, 403].includes(error.response.status)){
    this.refreshToken()
   }


  });  




  doLogin = action((userName: string, password: string) => {
    this.loggingIn = true
    cubaREST.login(userName, password).then((result) => {
   //   console.log(result)
      let response:any = result
      this.setToken(response)
      
      cubaREST.getUserInfo().then((userInfo) => {
        cubaREST.loadEntity('billing_Account', userInfo.id, {view:'account-rest-view'}).then((acc) => {
          this.accountStore.account = acc as Account
        })
      })
    }, (rejected) => {    
      if (rejected) {
        if (rejected.response?.status === 400) {
          this.publishSnackbarAlert('Login failed - bad credentials', 'error')
        } else {
          if (rejected) {
            let typeError = rejected as TypeError
            if (typeError.message === 'Failed to fetch') {
              this.publishSnackbarAlert('Unable to connect to the server, please try again later', 'error')
            } else {
              this.publishSnackbarAlert('Login failed', 'error')
            }
          }
        }
      }
      this.doLogoutActions()
    }).catch((error) => {
      alert(error)
    }).finally(() => {
      this.loggingIn = false
    })
  });

  setToken(token:any) {
    localStorage.setItem('billing_cubaAccessToken', token.access_token);
    localStorage.setItem('billing_refreshToken', token.refresh_token);

    const expiryMilliseconds = token.expires_in * 1000 ; // Convert seconds to milliseconds
    this.scheduleTokenRefresh(expiryMilliseconds);
  }

  scheduleTokenRefresh(expiresIn:number) {
    if (this.refreshTimeout) {
      clearTimeout(this.refreshTimeout);
    }

    // Set the refresh to happen a bit before the actual expiry time
    //const refreshTime = expiresIn * 1000 - 60000; // 1 minute before expiry
    
    const refreshTime = expiresIn - 600000; // 10 minutes before expiry
    this.refreshTimeout = setTimeout(() => {
      this.refreshToken();
    }, refreshTime);
  }

  refreshToken() {
    console.log('Attempting to refresh token')
    const refreshToken = localStorage.getItem('billing_refreshToken');
    if (refreshToken) {
      try {
        const xhr = new XMLHttpRequest();
        xhr.open('POST', `${process.env.REACT_APP_CUBA_URL}v2/oauth/token`, false); // false makes it synchronous
        xhr.setRequestHeader('Authorization', 'Basic ' + btoa(`${process.env.REACT_APP_REST_CLIENT_ID}:${process.env.REACT_APP_REST_CLIENT_SECRET}`));
        xhr.setRequestHeader('Content-Type', 'application/x-www-form-urlencoded');
  
        const params = new URLSearchParams({
          grant_type: 'refresh_token',
          refresh_token: refreshToken,
        }).toString();
  
        xhr.send(params);
  
        if (xhr.status === 200) {
          const data = JSON.parse(xhr.responseText);
          this.setToken(data)
  
        } else {
          console.log('Refresh token failed', xhr.statusText);
          this.doLogoutActions();
        }
      } catch (err) {
        console.log('Refresh token failed', err);
        this.doLogoutActions();
      }
    } else {
      this.doLogoutActions();
    }
  }
  

  attemptAutoLogin = action(async () => {
    this.loggingIn = true;
    try {
      const userInfo = await cubaREST.getUserInfo();
      this.promiseAccount = cubaREST.loadEntity('billing_Account', userInfo.id, { view: 'account-rest-view' });
      const acc = await this.promiseAccount;
      this.accountStore.account = acc;
      this.loggingIn = false;
    } catch (e) {
      const error:any = e;
      if (error.response?.status === 401) {
        this.refreshToken()
            const userInfo = await cubaREST.getUserInfo();
            this.promiseAccount = cubaREST.loadEntity('billing_Account', userInfo.id, { view: 'account-rest-view' });
            const acc = await this.promiseAccount;
            this.accountStore.account = acc;
        
      } else {
        this.doLogoutActions();
      }
    } finally {
      this.loggingIn = false;
    }
  });

  

  @action
  doLogoutActions() {
    localStorage.removeItem("billing_cubaAccessToken");
    localStorage.removeItem("billing_refreshToken");
    localStorage.clear();
    store.app.accountStore = new AccountStore(store.app)
    store.app.icpStore = new IcpStore(store.app)
    store.app.contractStore = new ContractStore(store.app)
    store.app.invoiceStore = new InvoiceStore(store.app)
    store.app.pricesStore = new PricesStore(store.app)

    this.accountStore.account = null
    this.connectionErrorCount = 0
    store.router.goTo(routes.login)
  }

  get getCubaRest() {
    return this.cubaRest
  }

  get accountIsLoaded() {
    return this.accountStore.account != null
  }

}


export default AppStore;