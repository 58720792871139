import { Button, Grid } from '@material-ui/core';
import { deepOrange } from '@material-ui/core/colors';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import { observer } from 'mobx-react-lite';
import React, { useContext } from 'react';
import PasswordReset from '../../../../components/PasswordReset/PasswordReset';
import { StoreContext } from '../../../../context/store-context';


const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      '& .MuiTextField-root': {
        margin: theme.spacing(6),
        width: '25ch',
      },
      padding: 5,
      display: 'block',

    },
    heading: {
      fontSize: theme.typography.pxToRem(20),
      fontWeight: theme.typography.fontWeightRegular,
    },
    orange: {
      color: theme.palette.getContrastText(deepOrange[500]),
      backgroundColor: deepOrange[500],
      //width: theme.spacing(7),
      // height: theme.spacing(7),
    },
    passwordReset: {
      display: 'flex',
      flex: 1
    }
  }),
);

const ProfilePage = observer(() => {
  const classes = useStyles();
  const store = useContext(StoreContext);


  const [passwordResetOpen, setPasswordResetOpen] = React.useState<boolean>(false);

  /*  const [state, setState] = React.useState<any>();
 
 
   const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
     setState({ ...state, [event.target.id]: event.target.value });
   }; */

  return (

    <div className={classes.root}>
      <form className={classes.root} noValidate autoComplete="off">
        <div>

          <Grid
            container
            direction="row"
            justify="space-between"
            alignItems="center"
            spacing={5}
          >

            <Grid item sm={12}>
              <Typography variant="h6" gutterBottom>
                User Information
              </Typography>


              <TextField
                id="first-name"
                label="First Name"
                multiline
                rowsMax={8}
                value={store.app.accountStore.account?.firstName}
                contentEditable={false}
              />
              <TextField
                id="last-name"
                label="Last Name"
                multiline
                rowsMax={8}
                value={store.app.accountStore.account?.lastName}
                contentEditable={false}
              />
            </Grid>
            <Grid item sm={12}>
              <Typography variant="h6" gutterBottom>
                Account Information:
              </Typography>
              <TextField
                id="name"
                label="Name | Organisation"
                multiline
                rowsMax={8}
                value={store.app.accountStore.account?.name}
                contentEditable={false}
              /*  InputProps={{
                 startAdornment: (
                   <InputAdornment position="start">
                     <Avatar className={classes.orange}>{store.app.accountStore.account?.firstName?.charAt(0)}{store.app.accountStore.account?.lastName?.charAt(0)}</Avatar>
                   </InputAdornment>
                 ),
               }} */
              />
              <TextField
                id="account-number"
                label="Account Number"
                multiline
                rowsMax={4}
                value={store.app.accountStore.account?.accountNumber}
                contentEditable={false}
              />
            </Grid>
            <Grid item sm={12}>
              <Typography variant="h6" gutterBottom>
                Contact methods:
              </Typography>
              <TextField
                id="home-phone"
                label="Home Phone"
                multiline
                rowsMax={8}
                value={store.app.accountStore.account?.mobilePhone}
                contentEditable={false}
              />
              <TextField
                id="mobile-phone"
                label="Mobile Phone"
                multiline
                rowsMax={8}
                value={store.app.accountStore.account?.workPhone}
                contentEditable={false}
              />
              <TextField
                id="work-phone"
                label="Work Phone"
                multiline
                rowsMax={8}
                value={store.app.accountStore.account?.workPhone}
                contentEditable={false}
              />
              <TextField
                id="email"
                label="Email"
                multiline
                rowsMax={8}
                value={store.app.accountStore.account?.email}
                contentEditable={false}
              />
            </Grid>


            <Grid item sm={12}>
              <Typography variant="h6" gutterBottom>
                Billing address:
              </Typography>
              <TextField
                id="address-line-one"
                label="Address Line 1"
                multiline
                rowsMax={8}
                value={store.app.accountStore.account?.billingAddress?.addressLineOne}
                contentEditable={false}
              />
              <TextField
                id="address-line-2"
                label="Address Line 2"
                multiline
                rowsMax={8}
                value={store.app.accountStore.account?.billingAddress?.addressLineTwo}
                contentEditable={false}
              />
              <TextField
                id="address-line-3"
                label="Address Line 3"
                multiline
                rowsMax={8}
                value={store.app.accountStore.account?.billingAddress?.addressLineThree}
                contentEditable={false}
              />
              <TextField
                id="address-city"
                label="City"
                multiline
                rowsMax={8}
                value={store.app.accountStore.account?.billingAddress?.city}
                contentEditable={false}
              />
              <TextField
                id="address-region"
                label="Region"
                multiline
                rowsMax={8}
                value={store.app.accountStore.account?.billingAddress?.region}
                contentEditable={false}
              />
              <TextField
                id="address-post-code"
                label="Postal Code"
                multiline
                rowsMax={8}
                value={store.app.accountStore.account?.billingAddress?.postCode}
                contentEditable={false}
              />
            </Grid>
          </Grid>

        </div>
       
          <Grid
            container
            direction="column"
            justify="center"
            alignItems="center"
            spacing={5}
          >
            <Grid item sm={12}>
             <Button variant="outlined" color="secondary" onClick={() => setPasswordResetOpen(true)}>
                CHANGE MY PASSWORD
              </Button>
              <PasswordReset open={passwordResetOpen} canClose={true} />
            </Grid>
          </Grid>
      </form>   
    </div>
  );
})

export default ProfilePage
