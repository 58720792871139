import { BaseUuidEntity } from "./base/sys$BaseUuidEntity";
export class AccountHhrReadsView extends BaseUuidEntity {
  static NAME = "billing_AccountHhrReadsView";
  account?: any | null;
  channelDescription?: string | null;
  contract?: any | null;
  energyFlowDirection?: string | null;
  flag?: string | null;
  icpIdentifier?: string | null;
  kwh?: any | null;
  meterChannel?: number | null;
  meterSerial?: string | null;
  period?: number | null;
  periodOfAvailability?: number | null;
  readDate?: any | null;
  registerContentCode?: string | null;
}
export type AccountHhrReadsViewViewName = "_base" | "_local" | "_minimal";
export type AccountHhrReadsViewView<
  V extends AccountHhrReadsViewViewName
> = V extends "_base"
  ? Pick<
      AccountHhrReadsView,
      | "id"
      | "account"
      | "channelDescription"
      | "contract"
      | "energyFlowDirection"
      | "flag"
      | "icpIdentifier"
      | "kwh"
      | "meterChannel"
      | "meterSerial"
      | "period"
      | "periodOfAvailability"
      | "readDate"
      | "registerContentCode"
    >
  : V extends "_local"
  ? Pick<
      AccountHhrReadsView,
      | "id"
      | "account"
      | "channelDescription"
      | "contract"
      | "energyFlowDirection"
      | "flag"
      | "icpIdentifier"
      | "kwh"
      | "meterChannel"
      | "meterSerial"
      | "period"
      | "periodOfAvailability"
      | "readDate"
      | "registerContentCode"
    >
  : never;
