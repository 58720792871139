import MuiAlert, { AlertProps } from '@material-ui/lab/Alert';
import React, { useContext } from 'react';
import { StoreContext } from '../../context/store-context';


interface SnackbarProps {
  handleClose: React.FC | any;
}

export const SnackbarAlert = (props:SnackbarProps) => {
    const store = useContext(StoreContext);

    function Alert(props: AlertProps) {
        return <MuiAlert elevation={6} variant="filled" {...props} />;
      } 

    return (
        <Alert onClose={props.handleClose} severity={store.app.snackBarAlert?.severity} >
          {store.app.snackBarAlert?.alertMessage} 
        </Alert>
    )
}





