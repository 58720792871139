import { Hidden } from '@material-ui/core';
import AppBar from '@material-ui/core/AppBar';
import Badge from '@material-ui/core/Badge';
import { grey } from '@material-ui/core/colors';
import CssBaseline from '@material-ui/core/CssBaseline';
import Divider from '@material-ui/core/Divider';
import Drawer from '@material-ui/core/Drawer';
import IconButton from '@material-ui/core/IconButton';
import { makeStyles } from '@material-ui/core/styles';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import EcoIcon from '@material-ui/icons/Eco';
import MenuIcon from '@material-ui/icons/Menu';
import NotificationsIcon from '@material-ui/icons/Notifications';
import clsx from 'clsx';
import { observer } from 'mobx-react-lite';
import React, { useContext } from 'react';
import AuthGuard from '../../AuthGuard/authguard';
import { StoreContext } from '../../context/store-context';
import routes from '../../routing/routes';
import Menu from './components/Menu';
import { ProfileMenu } from './components/ProfileMenu';
import HomePage from './pages/home/HomePage';
import IcpPage from './pages/icp/IcpPage';
import InvoicesPage from './pages/invoicing/InvoicesPage';
import NoPowerPage from './pages/nopower/NoPowerPage';
import Page from './pages/Page';
import ProfilePage from './pages/profile/ProfilePage';


const drawerWidth = 240;

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
  }, 
  toolbar: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  appBarShift: {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  menuButton: {
    marginRight: 36,
    marginLeft:2
  },
  menuButtonHidden: {
    display: 'none',
  },
  title: {
    flexGrow: 1,
    fontSize:27
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
    whiteSpace: 'nowrap',
    zIndex: 10,

  },
  drawerOpen: {
    width: drawerWidth,
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  drawerClose: {
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    overflowX: 'hidden',
    width: theme.spacing(0),
    [theme.breakpoints.up('sm')]: {
      width: theme.spacing(9) + 1,
    },
  },
  appBarSpacer: theme.mixins.toolbar,
  content: {
    flexGrow: 1,
    height: '100vh',
    width: theme.spacing(9) + 1
    //overflow: 'auto',
  },
  version:{
    position: 'fixed',
    bottom:0,
    right:0,
    //fontWeight: 500
    color: grey[300]
}
}));

export const Dashboard = observer(() => {
  const store = useContext(StoreContext);
  const { router: { params, goTo } } = store;
  const classes = useStyles();
  const [drawerOpen, setDrawerOpen] = React.useState(false);
  const [smallDrawerOpen, setSmallDrawOpenOpen] = React.useState(false);


  const handleDrawerOpen = () => {
    setDrawerOpen(true);
  };

  const handleSmallDrawerToggle = () => {
    setSmallDrawOpenOpen(!smallDrawerOpen)
  };

  const handleDrawerClose = () => {
    setDrawerOpen(false);
  };

  if (params?.tab === null) {
    goTo(routes.dashboard, { tab: 'home' })
  }    

  return (
    <AuthGuard>
      
      <div className={classes.root}>
        <CssBaseline />
        <AppBar position="fixed" className={clsx(classes.appBar, drawerOpen && classes.appBarShift)}>
          <Toolbar className={classes.toolbar}>

            {/* small menu toggle */}
            <Hidden smUp implementation="css">
              <IconButton
                edge="start"
                color="inherit"
                aria-label="open small drawer"
                onClick={handleSmallDrawerToggle}
                className={clsx(classes.menuButton, drawerOpen && classes.menuButtonHidden)}
              >
                <MenuIcon fontSize='large'/>
              </IconButton>
            </Hidden>

            {/* large menu toggle */}
            <Hidden xsDown implementation="css">
              <IconButton
                edge="start"
                color="inherit"
                aria-label="open drawer"
                onClick={handleDrawerOpen}
                className={clsx(classes.menuButton, drawerOpen && classes.menuButtonHidden)}
              >
                <MenuIcon fontSize='large' />
              </IconButton>
            </Hidden>

            <Typography component="h1" variant="h4" color="inherit" noWrap className={classes.title} >
              ecosmart<EcoIcon fontSize='inherit'/>
            </Typography>
            <Typography component="h6" variant="h6" color="inherit" noWrap >
              {/* {store.app.account?.login} */}
            </Typography>
            <IconButton color="inherit" >
              <Badge badgeContent={store.app.accountStore.unreadMessages} color="error">
                <NotificationsIcon fontSize='large' />
              </Badge>
            </IconButton>
            <ProfileMenu />
          </Toolbar>
        </AppBar>

        {/* small drawer menu */}
        <Hidden smUp>
          <Drawer
            variant="temporary"
            anchor={'left'}
            open={smallDrawerOpen}
            onClose={handleSmallDrawerToggle}      
            ModalProps={{
              keepMounted: true, // Better open performance on mobile.
            }}
          >
            <Menu drawerOpen={drawerOpen||smallDrawerOpen}/>
          </Drawer>

          {/* large drawer menu */}
        </Hidden>
        <Hidden xsDown implementation="css">
          <Drawer
            variant="permanent"
            className={clsx(classes.drawer, {
              [classes.drawerOpen]: drawerOpen,
              [classes.drawerClose]: !drawerOpen,
            })}
            classes={{
              paper: clsx({
                [classes.drawerOpen]: drawerOpen,
                [classes.drawerClose]: !drawerOpen,
              }),
            }}
          >
            <div className={classes.toolbar}>
              <IconButton onClick={handleDrawerClose}>
                <ChevronLeftIcon />
              </IconButton>
            </div>
            <Divider />
            <Menu drawerOpen={drawerOpen||smallDrawerOpen}/>
          </Drawer>
        </Hidden>



        <main className={classes.content}>
          <div className={classes.toolbar} />

          {store.app.accountStore.account?          
            params?.tab === 'home' && <div>
              <Page title={'Home Page'}>
                <HomePage />
              </Page>
            </div>
          :null}
          
          {store.app.accountStore.account?
            params?.tab === 'icp' && <div>
              <Page title={'Icp Page'}>
                <IcpPage />
              </Page>
            </div>
            :null}
          {store.app.accountStore.account?
            params?.tab === 'invoicing' && <div>
              <Page title={'Invoicing Page'}>
                <InvoicesPage />
              </Page>
            </div>
          :null}
          {store.app.accountStore.account?
            params?.tab === 'messaging' && <div>
              <Page title={'Messages Page'}>
                Coming soon...
              </Page>
            </div>
          :null}
          {store.app.accountStore.account?
            params?.tab === 'profile' && <div>
              <Page title={'Profile Page'}>
                <ProfilePage />
              </Page>
            </div>
          :null}
           {store.app.accountStore.account?
            params?.tab === 'no-power' && <div>
              <Page title={'No Power Page'}>
                <NoPowerPage />
              </Page>
            </div>
          :null}
        </main>
        <div className={classes.version}>
  {process.env.REACT_APP_BUILD_TAG 
    ? process.env.REACT_APP_BUILD_TAG
    : 'dev'}
</div>
      </div>
    </AuthGuard>
  );
});



