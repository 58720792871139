



export const bracketedNegativesFormat = (number:number) =>{

    if(number<0){
        return `(${Math.abs(number)})`
    }
    return number
}

export const bracketedNegativesFormatToCurrency = (number:number) =>{

    if(number<0){
        return `(${Math.abs(number).toFixed(2)})`
    }
    return number.toFixed(2)
}