
/* 
export const CUBA_APP_URL = 'https://billing.ecosmart.co.nz:8080/billing/rest/' ?? "/app/rest/";
export const REST_CLIENT_ID = process.env.REACT_APP_REST_CLIENT_ID ?? "billing-XvRi2nc7";
export const REST_CLIENT_SECRET =
  process.env.REACT_APP_REST_CLIENT_SECRET ?? "255c0a5e501dba10732c2e8c5a1fd1708831a565ae3fad374ac03fec2db27d9f";

export const DEFAULT_COUNT = 10; // Typical amount of entities to be loaded on browse screens
 */


export const CUBA_APP_URL = process.env.REACT_APP_CUBA_URL ?? "/app/rest/";
export const REST_CLIENT_ID = process.env.REACT_APP_REST_CLIENT_ID ?? "client";
export const REST_CLIENT_SECRET = process.env.REACT_APP_REST_CLIENT_SECRET ?? "secret";
export const DEFAULT_COUNT = 10; // Typical amount of entities to be loaded on browse screens


