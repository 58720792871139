import { observer } from 'mobx-react-lite';
import React, { useContext, useEffect } from 'react';
import { Bar, BarChart, Legend, ResponsiveContainer, Tooltip, XAxis } from 'recharts';
import { StoreContext } from '../../../../../../context/store-context';
import { AccountHhrImportExportView } from '../../../../../../cuba/entities/billing_AccountHhrImportExportView';
import { formatCubaDate } from '../../../../../../util/DateUtility';

const importedColor = "#0EA9E1" //'#118cd8'//
const exportedColor = "#65e03c" //'#14c823'//

export const MonthUsageChart = observer(() => {
  const store = useContext(StoreContext);
  const [hhrUsage, setHhrUsage] = React.useState<AccountHhrImportExportView[]>()

  useEffect(() => {
    store.app.cubaRest?.query(AccountHhrImportExportView.NAME, 'getHhrTotalsByDateForAccount',
      {
        readStartDate: formatCubaDate(store.app.contractStore.currentBillingPeriodSpend?.billingPeriod?.fromDate),
        readEndDate: formatCubaDate(store.app.contractStore.currentBillingPeriodSpend?.billingPeriod?.toDate)
      }).then((res) => {
        setHhrUsage(res as AccountHhrImportExportView[])
      }).finally(() => {

      })
  }, [store.app.cubaRest, store.app.contractStore.currentBillingPeriodSpend]);

  if (hhrUsage && store.app.contractStore.currentBillingPeriodSpend) {
    return (
      <ResponsiveContainer width="100%" height={250} >
        <BarChart //onClick={handleBarClick}  
          data={hhrUsage}
          margin={{
            top: 5, right: 30, left: 20, bottom: 5,
          }}
        >
          <XAxis dataKey="readDate"
            tickFormatter={formatXAxis}
          />
          <Tooltip labelFormatter={(date) => formatTooltipLabel(date.toString())} 
          cursor={{fill:'#d1f8ff'}}
          />
          <Legend />
          <Bar dataKey="importedVolume" fill={importedColor} name='Imported' />
          <Bar dataKey="exportedVolume" fill={exportedColor} name='Exported' />
        </BarChart>
      </ResponsiveContainer>
    );
  }
  return null
})

const formatXAxis = (date: string) => {
  const moment = require('moment');
  return moment(date).format('DD MMM')
}

const formatTooltipLabel = (date: string) => {
  const moment = require('moment');
  return moment(date).format('DD MMMM YYYY')
}

export default MonthUsageChart