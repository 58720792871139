import { Box, Grid } from '@material-ui/core';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import { observer } from 'mobx-react-lite';
import React, { useContext, useEffect } from 'react';
import { StoreContext } from '../../../../context/store-context';
import Page from '../Page';
import CurrentBalance from './components/CurrentBalance/index';
import CurrentSpend from './components/CurrentSpend/CurrentSpend';
import MonthOverviewPieChart from './components/MonthOverviewPie/MonthOverviewPieChart';
import MonthUsageChart from './components/MonthUsageChart/MonthUsageChart';
import FiveMinutePricesGauge from './components/SpotPrice/FiveMinutePricesGauge';


const useStyles = makeStyles((theme: Theme) =>

    createStyles({
        container: {
            marginTop: theme.spacing(3)
        },
        title: {
            fontSize: 14,
            textAlign: 'center'
          },
    }),
);

export const HomePage = observer(() => {
    const classes = useStyles();
    const store = useContext(StoreContext);
    const [greeting, setGreeting] = React.useState<string>()

    useEffect(() => {
        var myDate = new Date();
        var hrs = myDate.getHours();
        if (hrs < 12)
            setGreeting('Good morning')
        else if (hrs >= 12 && hrs <= 17)
            setGreeting('Good afternoon')
        else if (hrs >= 17 && hrs <= 24)
            setGreeting('Good evening')

        return () => {
            //this runs when the page unmounts for cleanup actions       
        }
    }, []);

    return (
        <Page>
            <Grid
                justify='center'
                className={classes.container}
                container
                spacing={2}
                direction='row'
            >
                <Grid
                    item
                    lg={3}
                    sm={6}
                    xs={12}

                >

                    <Box display="block" justifyContent="left">
                        <Typography component="h5" variant="h5" >
                            {greeting}, {store.app.accountStore.account?.firstName}
                        </Typography>
                        <Typography className={classes.title} color="textSecondary" gutterBottom>
                            Here's what's happening with your account
                            </Typography>
                    </Box>
                    <CurrentBalance />
                </Grid>
                <Grid
                    item
                    lg={4}
                    sm={6}
                    xs={12}
                >
                    <FiveMinutePricesGauge />

                </Grid>
                <Grid
                    item
                    lg={4}
                    sm={6}
                    xs={12}
                >
                    <CurrentSpend />
                </Grid>

            </Grid>
            <Box display="flex" justifyContent="center">
                <Grid
                    className={classes.container}
                    container
                    spacing={1}
                >
                    <Grid
                        item
                        lg={3}
                        sm={6}
                        xs={12}
                    >
                        <MonthOverviewPieChart />
                    </Grid>
                    <Grid
                        item
                        lg={9}
                        sm={6}
                        xs={12}
                    >
                        <MonthUsageChart />
                    </Grid>
                </Grid>
            </Box>
        </Page>
    );
})


export default HomePage