import { Button, IconButton } from '@material-ui/core';
import Snackbar from '@material-ui/core/Snackbar';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import CloseIcon from '@material-ui/icons/Close';
import { observer } from 'mobx-react-lite';
import { MobxRouter, Store } from 'mobx-router';
import React, { useContext } from 'react';
import { SnackbarAlert } from './components/SnackbarAlert/SnackbarAlert';
import { StoreContext } from './context/store-context';

interface Values {
    store: Store
}

const useStyles = makeStyles((theme: Theme) =>
    createStyles({     
        close: {
            padding: theme.spacing(2),
        },    
        backdrop: {
            zIndex: theme.zIndex.drawer + 1,
            color: '#000000',
          },
    }),
);

export const App = observer((props: Values) => {
    const classes = useStyles();
    const store = useContext(StoreContext);

    const handleSnackbarClose = (event?: React.SyntheticEvent, reason?: string) => {
        if (reason === 'clickaway') {
            return;
        }
        store.app.setSnackbarState(false)
    };

    
    return (
        <div>           
            <MobxRouter store={props.store} />
            <Snackbar open={store.app.snackbarOpen} autoHideDuration={6000} onClose={handleSnackbarClose} action={
                <React.Fragment>
                    <Button color="secondary" size="small" onClick={handleSnackbarClose}>
                        UNDO
                    </Button>
                    <IconButton
                        aria-label="close"
                        color="inherit"
                        className={classes.close}
                        onClick={handleSnackbarClose}
                    >
                        <CloseIcon />
                    </IconButton>
                </React.Fragment>
            }>
                <SnackbarAlert handleClose={handleSnackbarClose} />
            </Snackbar>       
           
        </div>
    )
}

)


