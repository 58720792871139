import { Route } from 'mobx-router';
import React from 'react';
import { Book } from '../components/Book';
import { Document } from '../components/Document';
import { Gallery } from '../components/Gallery';
import { Dashboard } from '../pages/Dashboard/Dashboard';
import InvoicePdf from '../pages/Dashboard/pages/invoicing/components/InvoicePdf';
import ForgotPassword from '../pages/Login/ForgotPassword';
import Login from '../pages/Login/Login';
import ResetPassword from '../pages/ResetPassword/ResetPassword';
/* import SignupPage from '../pages/signup/SignupPage'; */
import { RootStore } from '../store/store';

const routes = {
  home: new Route<RootStore>({
    path: '/',
    onEnter: (route, params, store, queryParams) => {
      store.router.goTo(routes.login)
    },
    component: <p>Redirecting</p>
  }),
 /*  signUp: new Route<RootStore>({
    path: '/sign-up',
    component: <SignupPage />
  }), */
  login: new Route<RootStore>({
    path: '/login',
    component: <Login />
  }),
  forgotPassword: new Route<RootStore>({
    path: '/forgot-password',
    component: <ForgotPassword />
  }), 
  resetPassword: new Route<RootStore, { 
    token?: string 
  }>({
    path: '/reset-password/:token',
    component: <ResetPassword />,   
  }),
  dashboard: new Route<RootStore, {
    tab?: string;
  }>({
    path: '/dashboard/:tab',
    component: <Dashboard />,
    beforeExit: () => {
      //console.log('Routes:exiting dashboard tab');
    },
    onParamsChange: (route, params) => {
      //console.log('Routes:params changed to', params);
    }
  },
  ),
  invoice: new Route<RootStore, {
    id: number
  }>({
    path: '/dashboard/invoice/:id',
    component: <InvoicePdf />,
  }),
  gallery: new Route<RootStore, any, any>({
    path: '/gallery',
    component: <Gallery />,
    beforeExit: () => {
      const result = window.confirm('Are you sure you want to leave the gallery?');
      return result;
    },
    onEnter: (route, params, store, queryParams) => {
      //console.log('queryParams', queryParams);
    }
  }),

  document: new Route<RootStore, {}, { id: number }>({
    path: '/document/:id',
    component: <Document />,
    beforeEnter: (route, params, store) => {
      const userIsLoggedIn = store.app.user;
      if (!userIsLoggedIn) {
        alert('Only logged in users can enter this route!');
        return false;
      }
    },
    onEnter: (route, params) => {
      //console.log(`entering document with params`, params);
    }
  }),
  book: new Route<RootStore, {}, {
    id: string,
    page: string,
  }>({
    path: '/book/:id/page/:page',
    component: <Book />,
    onEnter: (route, params, store) => {
      //console.log(`entering book with params`, params);
      store.app.setTitle(route.title);
    }
  })
  , catchAll: new Route<RootStore>({
    path: '*',
    onEnter: (route, params, store) => {
      store.router.goTo(routes.login)
    },
    
    component: <Login />,
  }),
};
export default routes;