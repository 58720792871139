import { CircularProgress, Grid } from '@material-ui/core';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import IconButton from '@material-ui/core/IconButton';
import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from "@material-ui/core/TableCell";
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Typography from '@material-ui/core/Typography';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import { observer } from 'mobx-react-lite';
import React, { useContext, useEffect } from 'react';
import { Bar, BarChart, Legend, ResponsiveContainer, Tooltip, XAxis } from 'recharts';
import { StoreContext } from '../../../../../context/store-context';

const TAX_RATE = 0.15;

export const UsageOverviewChart = observer(() => {
    const store = useContext(StoreContext);
    const classes = useStyles();
    const [open, setOpen] = React.useState(false);

    useEffect(() => {
        store.app.icpStore.initUsageOverviewDates()
        return () => {
        }
    }, [store.app.icpStore]);

    function formatDate(date: Date | undefined) {
        const moment = require('moment');
        return moment(date).format('DD MMM')
    }

    function formatDateWithYear(date: Date | undefined) {
        const moment = require('moment');
        return moment(date).format('DD MMMM YYYY')
    }

    const gotoPreviousWeek = () => {
        updateDates(-7)
    }

    const gotoNextWeek = () => {
        updateDates(7)
    }


    function updateDates(days: number) {
        if (store.app.icpStore.usageOverviewDates) {
            let fromDate: Date = new Date(store.app.icpStore.usageOverviewDates.fromDate)
            let toDate: Date = new Date(store.app.icpStore.usageOverviewDates.toDate)
            fromDate.setDate(fromDate.getDate() + days)
            toDate.setDate(toDate.getDate() + days)
            store.app.icpStore.usageOverviewDates = { fromDate: fromDate, toDate: toDate }

        }
    }

    function ccyFormat(num: number) {
        if (num) {
            return `${num.toFixed(2)}`;
        }

    }


    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };



    const handleBarClick = (params: any) => {
        store.app.contractStore.generateDaySpendLines(params.activeLabel)
        handleClickOpen()

    }

    return (
        <div>
            <Card className={classes.root} >
                <CardContent >
                    <div>
                        <Grid
                            justify="space-between" // Add it here :)
                            container
                        >
                            <Grid item>
                                <IconButton aria-label="delete" color="primary" onClick={gotoPreviousWeek}>
                                    <ChevronLeftIcon />
                                </IconButton>
                            </Grid>
                            <Grid item>
                                <div>
                                    <Typography className={classes.title} color="textSecondary" gutterBottom>
                                        Usage Overview
                        </Typography>
                                    <Typography className={classes.dateDescription} color="textSecondary" gutterBottom>
                                        {formatDate(store.app.icpStore.usageOverviewDates?.fromDate)} - {formatDate(store.app.icpStore.usageOverviewDates?.toDate)}
                                    </Typography>
                                </div>
                            </Grid>

                            <Grid item>
                                <IconButton aria-label="delete" color="primary" onClick={gotoNextWeek}>
                                    <ChevronRightIcon />
                                </IconButton>
                            </Grid>
                        </Grid>

                        <Box display="flex" flexDirection="row">

                            <ResponsiveContainer width="100%" minHeight={400} >
                                <BarChart onClick={handleBarClick} height={400}
                                    data={store.app.icpStore.currentHhrTotalsSet}
                                    margin={{
                                        top: 5, right: 30, left: 20, bottom: 5,
                                    }}
                                >
                                    <XAxis dataKey="readDate" tickFormatter={formatXAxis} />
                                    <Tooltip labelFormatter={(date) => formatTooltipLabel(date.toString())} 
                                    cursor={{fill: '#d1f8ff'}}
                                    />
                                    <Legend />
                                    <Bar dataKey="importedVolume" fill="#0EA9E1" name='Imported' />
                                    <Bar dataKey="exportedVolume" fill="#0EE147" name='Exported' />

                                </BarChart>
                            </ResponsiveContainer>

                        </Box>
                    </div>

                </CardContent>

            </Card>


            <Dialog
                fullScreen={false}
                open={open}
                onClose={handleClose}
                aria-labelledby="responsive-dialog-title"

            >
                <DialogTitle id="responsive-dialog-title">{`Spend Overview`}

                    <Typography className={classes.dateDescription} color="textSecondary" gutterBottom>
                        {formatDateWithYear(store.app.contractStore.daySpendObject?.date)}
                    </Typography>
                </DialogTitle>
                <DialogContent>

                    {store.app.contractStore.calculatingSpend ?
                        <CircularProgress
                            variant="indeterminate"
                            disableShrink
                            className={classes.spinner}
                            classes={{
                                circle: classes.circle,
                            }}
                            size={40}
                            thickness={4}
                            style={{ marginLeft: '40%' }}

                        />
                        :


                        <Table size="small" aria-label="spanning table">
                            <TableHead>
                                <TableRow >
                                    <TableCell>Desc</TableCell>
                                    <TableCell align="left">Qty.</TableCell>
                                    <TableCell align="left">Rate</TableCell>
                                    <TableCell align="left">Subtotal</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {store.app.contractStore.daySpendObject?.lines?.map((row) => (
                                    <TableRow key={row.id} hover={true}>
                                        <TableCell>{row.lineDescription}</TableCell>
                                        <TableCell align="left">{row.quantity}</TableCell>
                                        <TableCell align="left">{row.rate}</TableCell>
                                        <TableCell align="left">{ccyFormat(row.quantity * row.rate)}</TableCell>
                                    </TableRow>
                                ))}
                                <TableRow>
                                    <TableCell className={classes.topBorder} />
                                    <TableCell className={classes.topBorder} />
                                    <TableCell className={classes.topBorder} />
                                    <TableCell className={classes.topBorder} />
                                    <TableCell className={classes.topBorder} />
                                </TableRow>

                                <TableRow>
                                    <TableCell rowSpan={3} />
                                    <TableCell colSpan={2}>Subtotal</TableCell>
                                    <TableCell align="right">{store.app.contractStore.daySpendObject?.subTotal}</TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell>Tax</TableCell>
                                    <TableCell align="right">{`${(TAX_RATE * 100).toFixed(0)} %`}</TableCell>
                                    <TableCell align="right">{store.app.contractStore.daySpendObject?.gst}</TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell colSpan={2}>Total</TableCell>
                                    <TableCell className={classes.tableBorders} align="right">${store.app.contractStore.daySpendObject?.total}</TableCell>
                                </TableRow>
                            </TableBody>
                        </Table>


                    }

                </DialogContent>
                <DialogActions>
                    {store.app.contractStore.calculatingSpend ? null :
                        <Typography className={classes.warning} color="textSecondary" gutterBottom>
                            Note: This data could be incomplete and is subject to change
                    </Typography>
                    }
                    <Button autoFocus onClick={handleClose} color="primary">
                        OK
                    </Button>
                </DialogActions>
            </Dialog>
        </div>

    );
})



const useStyles = makeStyles({
    root: {
        minWidth: 275,
        marginBottom: 12,
        marginTop: 12,
        height: '75vh'

    },
    bullet: {
        display: 'inline-block',
        margin: '0 2px',
        transform: 'scale(0.8)',
    },
    title: {
        fontSize: 18,
        textAlign: 'center'
    },
    dateDescription: {
        fontSize: 14,
        textAlign: 'center'
    },
    pos: {
        marginBottom: 12,
        marginTop: 25,
    },
    circle: {
        strokeLinecap: 'round',
        color: '#1a90ff',
        animationDuration: '550ms',

    },

    spinner: {
        color: '#1a90ff',
        animationDuration: '550ms',
    },
    tableBorders: {
        borderWidth: 1,
        borderColor: 'gray',
        borderStyle: 'solid',
        borderTopWidth: 10
    }, // or borderTop: '1px solid red'
    tableNoBorder: {
        borderWidth: 1,
        borderColor: 'red',
        borderStyle: 'solid',

    }, // or borderTop: '1px solid red'
    topBorder: {
        borderColor: 'gray',
        borderTopWidth: 2
    }, // or borderTop: '1px solid red'
    warning: {
        borderColor: 'gray',
        borderTopWidth: 2,
        color: 'red',
        fontSize: 10
    }, // or bord



});

const formatXAxis = (date: string) => {
    const moment = require('moment');
    return moment(date).format('DD MMM')
}

const formatTooltipLabel = (date: string) => {
    const moment = require('moment');
    return moment(date).format('DD MMMM YYYY')
}

export default UsageOverviewChart