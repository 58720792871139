import { Grid } from '@material-ui/core';
import AppBar from '@material-ui/core/AppBar';
import Box from '@material-ui/core/Box';
import { makeStyles, Theme } from '@material-ui/core/styles';
import Tab from '@material-ui/core/Tab';
import Tabs from '@material-ui/core/Tabs';
import { observer } from 'mobx-react-lite';
import React from 'react';
import FinalPricesChart from './components/final-prices/FinalPricesChart';
import MeterReadingsChart from './components/MeterReadingsChart';
import UsageOverviewChart from './components/UsageOverviewChart';


interface TabPanelProps {
    children?: React.ReactNode;
    index: any;
    value: any;
}

function TabPanel(props: TabPanelProps) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`full-width-tabpanel-${index}`}
            aria-labelledby={`full-width-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box p={0} >
                    {children}
                </Box>
            )}
        </div>
    );
}


const useStyles = makeStyles((theme: Theme) => ({
    root: {
        flexGrow: 1,
        backgroundColor: theme.palette.background.paper,
    },
    container: {
        marginTop: theme.spacing(1),
        paddingLeft: 0,
        paddingRight: 0,
        paddingTop: 0,
        paddingBottom: 0,
    },
    appBar: {
        background: 'white',
        position: '-webkit-sticky',
        top: 50,
        bottom: 20,
        zIndex: 5,
    },
    tabPanelBox: {
        paddingLeft: 0,
        paddingRight: 0,
        paddingTop: 0,
        paddingBottom: 0,
    },
}));


export const IcpPage = observer(() => {
    const classes = useStyles();
    const [value, setValue] = React.useState(0);

    const handleChange = (event: React.ChangeEvent<{}>, newValue: number) => {
        setValue(newValue);
    };

    return (

        <div>
            <AppBar position="sticky" color="default" className={classes.appBar} >
                <Tabs
                    variant="fullWidth"
                    value={value}
                    onChange={handleChange}
                    aria-label="icp nav"
                >
                    <Tab label="Overview" aria-label="overview" />
                    <Tab label="Metering" aria-label="metering" />
                    <Tab label="Pricing" aria-label="pricing" />

                </Tabs>
            </AppBar>
            <TabPanel value={value} index={0}>
                <Grid
                    className={classes.container}
                    container
                    spacing={3}
                >
                {/*     <Grid
                        item
                        container
                        spacing={0}
                        direction="column"
                        alignItems="center"
                        justify="center"
                        lg={3}
                        sm={3}
                        xs={12}
                    >
                        <FiveMinutePricesGauge />
                    </Grid> */}
                    <Grid
                        item
                        lg={12}
                        sm={12}
                        xs={12}
                    >
                        <UsageOverviewChart />
                    </Grid>


                </Grid>
            </TabPanel>
            <TabPanel value={value} index={1}>
                <Grid
                    className={classes.container}
                    container
                    spacing={3}
                >
                    <Grid
                        item
                        lg={12}
                        sm={12}
                        xs={12}
                    >
                        <MeterReadingsChart />
                    </Grid>
                </Grid>
            </TabPanel>
            <TabPanel value={value} index={2}>
                <Grid
                    className={classes.container}
                    container
                    spacing={3}
                >
                    <Grid
                        item
                        lg={12}
                        sm={12}
                        xs={12}
                    >
                        <FinalPricesChart />
                        
                    </Grid>
                </Grid>
            </TabPanel>
        </div>
    );
})


export default IcpPage