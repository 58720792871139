import { observer } from 'mobx-react-lite';
import React, { useContext, useEffect } from 'react';
import { Legend, Line, LineChart, ResponsiveContainer, Tooltip, XAxis, YAxis } from 'recharts';
import { StoreContext } from '../../../../../../../../context/store-context';

const moment = require('moment');

const MonthlyChart = observer(() => {
    const store = useContext(StoreContext);

    let effectVariable = store.app.pricesStore?.pricesDate

    useEffect(() => {
        store.app.pricesStore.updateFinalPricesForMonth(store.app.pricesStore.pricesDate!)
    }, [store.app.pricesStore, effectVariable]);


    const ConvertDate = (date: Date) => {
        return moment(date).format('DD MMM') as string
    }

    return (

        <ResponsiveContainer width="100%" height={350}  >
            <LineChart /* barCategoryGap='30%' */ height={400}
                data={store.app.pricesStore.finalPricesMonthSet?.finalPrices}
                margin={{ top: 10, right: 0, left: 0, bottom: 0 }}
            >
                <defs>
                    <linearGradient id="colorUv" x1="0%" y1="0%" x2="0%" y2="100%">
                        <stop offset="0%" stopColor="red" />
                        <stop offset="50%" stopColor="orange" />
                        <stop offset="100%" stopColor="#53e206" />
                    </linearGradient>
                </defs>
                <XAxis
                    dataKey={'date'}
                    type='category'
                    axisLine={true}
                    padding={{ left: 15, right: 15 }}
                    allowDataOverflow={false}
                    tickFormatter={(tick) => ConvertDate(tick)}
                />
                <YAxis orientation={'right'} type={'number'} width={30} />

                <Tooltip
                    labelFormatter={(label) => 'Date: ' + ConvertDate(moment(new Date(label)).format('DD MMMM yyyy'))}
                    formatter={(price) => (price as number).toFixed(2) + 'c per kwh'}
                />

                <Legend />
                <Line
                    type="monotone"
                    key={'price'}
                    dataKey={'price'}
                    strokeWidth={8}
                    legendType="rect"
                    name={'Price'}
                    fill="url(#colorUv)"
                    stroke="url(#colorUv)"
                    color={'black'}
                    dot={false}
                >
                </Line>
            </LineChart>
        </ResponsiveContainer>

    )
})


export default MonthlyChart