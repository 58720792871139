/* eslint-disable no-undef */
import { Box } from '@material-ui/core';
import { observer } from 'mobx-react-lite';
import React, { useEffect } from 'react';

const NODE_ENV = process.env.NODE_ENV;

const Page = observer((props: any) => {
  const { title, children, ...rest } = props;

  useEffect(() => {
    if (NODE_ENV !== 'production') {
      return;
    }
  }, [title]);  

  return (
    <div {...rest}>
      <Box color="text.primary" padding={2} >         
        {children}
      </Box>      
    </div>
  );
});

export default Page;
