import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Divider, Typography } from '@material-ui/core';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import React from 'react';
import addToHome from '../images/add_to_home_screen_iphone.png';
import sendTo from '../images/apple-send-to-icon.png';
import useIosInstallPrompt from './UseIOSInstallPrompt';
import useWebInstallPrompt from './UseWebInstallPrompt';


const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: 'flex',
      maxWidth:800,
      padding:theme.spacing(2)
    },
    details: {
      display: 'flex',
      flexDirection: 'column',
    },
    content: {
      flex: '1 0 auto',
    },
    cover: {
      width: 151,
    },
    controls: {
      display: 'flex',
      alignItems: 'center',
      paddingLeft: theme.spacing(1),
      paddingBottom: theme.spacing(1),
    },
    playIcon: {
      height: 38,
      width: 38,
    },
    imgcenter: {
      display: 'block',
      marginLeft: 'auto',
      marginRight: 'auto',
    },
    section1: {
      margin: theme.spacing(3, 2),
    },
    section2: {
      margin: theme.spacing(2),
    },
    sendTo: {
      height: 30,
      width: 30,
 }
    
  }),
);


export default function AlertDialog() {
  const [open, setOpen] = React.useState(true);
  const classes = useStyles();

  const handleClose = () => {
    setOpen(false);
  };

  const [iosInstallPrompt] = useIosInstallPrompt();
  const [webInstallPrompt, handleWebInstallAccepted] = useWebInstallPrompt();
  


  if (!iosInstallPrompt && !webInstallPrompt) {
    return null;
  }

  return (
    <div>     
        <Dialog fullScreen 
        open={open.valueOf() && iosInstallPrompt}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{"Install the Ecosmart App"}</DialogTitle>
        <DialogContent>        
          <DialogContentText id="alert-dialog-description">        
          <div className={classes.section1}>
          <Typography variant="h5" color="textSecondary">
            For significantly increased perfomance, this app should be installed to your Apple device.
            
          </Typography>
          </div>
          <Divider variant="middle" />

          <div className={classes.section2}>          
          <Typography variant="h5" color="textSecondary">
            <strong>Step 1:</strong>  Click on the 'send-to' icon in your web browser.
            The icon looks like this:  <img className={classes.sendTo} src={sendTo} alt="sendTo"/>
          </Typography>      
          <Typography variant="body1" color="textSecondary">
          <strong>(note: you must be using the 'Safari Web Browser' on your Apple device)</strong>
          </Typography>                
          
          </div>
          <div className={classes.section2}>       
            <Typography variant="h5" color="textSecondary">
            <strong>Step 2:</strong>  On the pop-up.  Scroll down and select Add to Home Screen [+] .           
          </Typography>
          <img className={classes.imgcenter} src={addToHome} alt="addToHome"/>
          </div>

          <div className={classes.section2}>       
             <Typography variant="h5" color="textSecondary">
             <strong>Step 3:</strong>  When prompted to install the app,  select [add].         
          </Typography>
          </div>

          <div className={classes.section2}>       
             <Typography variant="h5" color="textSecondary">
             <strong>Step 4:</strong>  Close this page and open the Ecosmart App from your device's homescreen.         
          </Typography>
          </div>         
       
        </DialogContentText>
        <Button onClick={handleClose} color="primary" autoFocus>
            I'll do later...
          </Button>
        </DialogContent>     
      </Dialog>
        


      <Dialog fullScreen 
        open={open && webInstallPrompt}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{"Install the Ecosmart App"}</DialogTitle>
        <DialogContent>   
        <Typography variant="body1" color="textSecondary">
          For significantly increased performance and a much better user experience, this Ecosmart App should be installed to your device.
          </Typography>
          <Typography variant="body1" color="textSecondary">
          Would you like to install it now?
          </Typography>
           
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Cancel
          </Button>
          <Button onClick={handleWebInstallAccepted} color="primary" autoFocus>
            Install App
          </Button>
        </DialogActions>
      </Dialog>

    </div>
  );
}