import IconButton from '@material-ui/core/Button';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import AccountCircleIcon from '@material-ui/icons/AccountCircle';
import { observer } from 'mobx-react-lite';
import React, { useContext } from 'react';
import { StoreContext } from '../../../context/store-context';
import routes from '../../../routing/routes';

export const ProfileMenu = observer(() => {
    const store = useContext(StoreContext);
    const { router: { goTo } } = store;
    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);


    const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget);
    };

    const gotoProfilePage = () => {
        goTo(routes.dashboard, {tab: 'profile'})
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const handleLogout = () => {
        store.app.doLogoutActions()
        goTo(routes.login)
    };

    return (
        <div>

            <IconButton color="inherit" aria-controls="simple-menu" aria-haspopup="true" onClick={handleClick} >
                <AccountCircleIcon color='inherit' fontSize='large'/>
            </IconButton>
            <Menu
                id="simple-menu"
                anchorEl={anchorEl}
                keepMounted
                open={Boolean(anchorEl)}
                onClose={handleClose}
            >
                <MenuItem onClick={gotoProfilePage}>My Details</MenuItem>
                <MenuItem onClick={handleLogout}>Logout</MenuItem>
            </Menu>
            
        </div>
    )
})
