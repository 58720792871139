import { observer } from 'mobx-react-lite';
import React, { Fragment, useContext, useEffect } from 'react';
import PasswordReset from '../components/PasswordReset/PasswordReset';
import { StoreContext } from '../context/store-context';
import routes from '../routing/routes';

// Example of user roles: ['GUEST', 'USER', 'ADMIN'];

const AuthGuard = observer((props: any) => {
  const { children } = props;
  const store = useContext(StoreContext);
  const { router: { goTo } } = store;
  


  useEffect(() => {
    if (!store.app.accountStore.account) {
      goTo(routes.login) 
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [store.app.accountStore.account, store.app.accountStore.account?.changePasswordAtNextLogon]);

/*   useEffect(() => {
    if (store.app.accountStore.account) {
      if (store.app.accountStore.account.changePasswordAtNextLogon) {
        return
      }
      return;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [store.app.accountStore.account]); */

  return <Fragment>
    {store.app.accountStore.account?.changePasswordAtNextLogon ? <PasswordReset open={true} canClose={false} passwordResetTitle={'Password Reset Required'}/> : null}
    {children}
  </Fragment>;
});

export default AuthGuard;
