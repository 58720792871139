import DateFnsUtils from '@date-io/date-fns';
import { Card, FormHelperText, Grid, Paper } from '@material-ui/core';
import Box from '@material-ui/core/Box';
import CardContent from '@material-ui/core/CardContent';
import FormControl from '@material-ui/core/FormControl';
import IconButton from '@material-ui/core/IconButton';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import { KeyboardDatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import { observer } from 'mobx-react-lite';
import React, { useContext } from 'react';
import GaugeChart from 'react-gauge-chart';
import { StoreContext } from '../../../../../../context/store-context';
import DailyPricesChart from './components/charts/DailyChart';
import MonthlyChart from './components/charts/MonthlyChart';
import YearlyChart from './components/charts/YearlyChart';


const moment = require('moment');

export const FinalPricesChart = observer(() => {
    const store = useContext(StoreContext);
    const classes = useStyles();
    const [scope, setScope] = React.useState<string>('Day')



    const handleScopeChange = (event: React.ChangeEvent<{ value: unknown }>) => {
        setScope(event.target.value as string)
    };


    const gotoPrevious = () => {
        let date = new Date(store.app.pricesStore.pricesDate)

        if (date) {
            if (scope === 'Day') {
                date.setDate(date.getDate() - 1);
                handleDateChange(date)
            } else if (scope === 'Month') {
                date.setDate(1);
                date.setMonth(date.getMonth() - 1);
                handleDateChange(date)
            } else if (scope === 'Year') {
                date.setDate(1);
                date.setMonth(date.getMonth() - 12);
                handleDateChange(date)
            }
        }
    }

    const gotoNext = () => {
        let date = new Date(store.app.pricesStore.pricesDate)
        if (date) {

            if (scope === 'Day') {
                date.setDate(date.getDate() + 1);
                handleDateChange(date)
            } else if (scope === 'Month') {
                date.setDate(1);
                date.setMonth(date.getMonth() + 1);
                handleDateChange(date)
            } else if (scope === 'Year') {
                date.setDate(1);
                date.setMonth(date.getMonth() + 12);
                handleDateChange(date)
            }
        }
    }

    const handleDateChange = (date: Date | null) => {
        if (date) {
            store.app.pricesStore.pricesDate = date
        }
    };

    const getDatePicker = (scope: string) => {

        switch (scope) {
            case 'Day': return (
                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                    <KeyboardDatePicker
                        margin="normal"
                        id="date-picker-dialog"
                        label="Final Prices"
                        views={['date']}
                        format="dd/MM/yyyy"
                        value={new Date(store.app.pricesStore.pricesDate)}
                        onChange={handleDateChange}
                        KeyboardButtonProps={{
                            'aria-label': 'change date',
                        }}
                    />
                </MuiPickersUtilsProvider>
            )
            case 'Month': return (
                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                    <KeyboardDatePicker
                        margin="normal"
                        id="date-picker-dialog"
                        label="Final Prices"
                        views={['month']}
                        format="MMM yyyy"
                        value={store.app.pricesStore.pricesDate}
                        onChange={handleDateChange}
                        KeyboardButtonProps={{
                            'aria-label': 'change date',
                        }}
                    />
                </MuiPickersUtilsProvider>
            )
            case 'Year': return (
                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                    <KeyboardDatePicker
                        margin="normal"
                        id="date-picker-dialog"
                        label="Final Prices"
                        views={['year']}
                        format="yyyy"
                        value={new Date(moment(store.app.pricesStore.pricesDate).format('yyyy-MM-DDTHH:mm:SS'))}
                        onChange={handleDateChange}
                        KeyboardButtonProps={{
                            'aria-label': 'change date',
                        }}
                    />
                </MuiPickersUtilsProvider>
            )

        }
    }
    const calculatePercentage = () => {
        if (store.app.pricesStore.finalPricesSet) {
            let price = store.app.pricesStore.averagePrice
            if (price) {
                let calc = Math.round((((100 / 30) * price) / 100) * 10) / 10
                return calc > 1 ? 1 : calc
            }
            return null
        }
    }



    return (

        <Paper>

            <Card className={classes.root}>

                <CardContent >
                    <MuiPickersUtilsProvider utils={DateFnsUtils}>
                        <Grid
                            justify="space-between" // Add it here :)
                            container
                        >
                            <Grid
                                container
                                item
                                spacing={0}
                                direction="row"
                                alignItems="center"
                                justify="center"
                                lg={12}
                                sm={12}
                                xs={12}
                            >
                                <Grid item
                                    lg={8}
                                    sm={8}
                                    xs={8}
                                >
                                    <Box display="flex" justifyContent="flex-start" m={0} p={0} bgcolor="background.paper">
                                        <Box p={1} bgcolor="grey.300">
                                            <IconButton aria-label="delete" color="primary" onClick={gotoPrevious}>
                                                <ChevronLeftIcon />
                                            </IconButton>
                                        </Box>
                                        <Box p={1} bgcolor="grey.300">
                                            {getDatePicker(scope)}
                                        </Box>
                                        <Box p={1} bgcolor="grey.300">
                                            <IconButton aria-label="delete" color="primary" onClick={gotoNext}>
                                                <ChevronRightIcon />
                                            </IconButton>
                                        </Box>
                                       
                                    </Box >
                                    <Box p={1} display="flex" justifyContent="center">
                                            <FormControl className={classes.formControl} >
                                                <Select
                                                    value={scope}
                                                    //onChange={handleChange}
                                                    displayEmpty
                                                    className={classes.selectEmpty}
                                                    inputProps={{ 'aria-label': 'Without label' }}
                                                    onChange={handleScopeChange}
                                                    style={{ width: 75 }}
                                                >
                                                    <MenuItem value={'Day'}>Day</MenuItem>
                                                    <MenuItem value={'Month'}>Month</MenuItem>
                                                    <MenuItem value={'Year'}>Year</MenuItem>
                                                </Select>
                                                <FormHelperText>Scope [{scope}]</FormHelperText>
                                            </FormControl>
                                        </Box>
                                </Grid>

                                <Grid item
                                    lg={2}
                                    sm={2}
                                    xs={2}
                                >
                                    {store.app.pricesStore.averagePrice ?
                                        <div>
                                            <GaugeChart id="gauge-chart5"
                                                nrOfLevels={420}
                                                arcsLength={[0.3, 0.5, 0.2]}
                                                colors={['#5BE12C', '#F5CD19', '#EA4228']}
                                                percent={calculatePercentage()!}
                                                animate={true}
                                                arcPadding={0.02}
                                                textColor='black'
                                                needleColor='#bcbcbc'
                                                formatTextValue={(() => `${store.app.pricesStore.averagePrice!.toFixed(2)}c`)}
                                            />
                                            <Typography className={classes.title} color="textSecondary" gutterBottom>
                                                Average price: {store.app.pricesStore.averagePrice.toFixed(2)}c
                                    </Typography>
                                        </div>
                                        : null}
                                </Grid>

                            </Grid>


                            <Grid item
                                lg={12}
                                sm={12}
                                xs={12}>


                                {scope === 'Day' ? <DailyPricesChart /> : null}
                                {scope === 'Month' ? <MonthlyChart /> : null}
                                {scope === 'Year' ? <YearlyChart /> : null}


                            </Grid>
                        </Grid>
                    </MuiPickersUtilsProvider>
                </CardContent>

            </Card >
        </Paper>


    );
})

const useStyles = makeStyles({
    root: {
        minWidth: 275,
        marginBottom: 12,
        marginTop: 12,
        height: '75vh'

    },
    topCard: {
        height: '10vh',
        marginBottom: 10,
        marginTop: 10,
        padding: 10,
        maxHeight: 75

    },
    bullet: {
        display: 'inline-block',
        margin: '0 2px',
        transform: 'scale(0.8)',
    },
    title: {
        fontSize: 14,
        textAlign: 'center'
    },
    pos: {
        marginBottom: 12,
        marginTop: 25,
    },
    formControl: {

        minWidth: 120,
    },
    selectEmpty: {

    },
});



export default FinalPricesChart