import { autorun, observable } from 'mobx';
import { ICP } from '../../../cuba/entities/base/ecos_ICP';
import AppStore from '../app-store';

class SignupStore {

  @observable
  selectedIcp: ICP | undefined

  @observable
  icpSearchResults: ICP[] | undefined

  @observable
  icpConfirmed: boolean = false

  public appStore?: AppStore

  constructor(appStore: AppStore) {
    this.appStore = appStore

    //cleanup icp changed actions
    autorun(() => {
      if (this.selectedIcp) {
       this.icpConfirmed = false
      }
    })
  }
}



export default SignupStore
