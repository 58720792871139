

 const moment = require('moment');


export const formatDate = ((date: Date) => {   
    return moment(date).format('DD MMMM YYYY')
})


export const formatCubaDate = ((date: Date) => {
    return moment(date).format('YYYY-MM-DD')
})


export const convertMonthLong = (month: any) => {
    switch (month){
        case 1: return 'January'
        case 2: return 'February'
        case 3: return 'March'
        case 4: return 'April'
        case 5: return 'May'
        case 6: return 'June'
        case 7: return 'July'
        case 8: return 'August'
        case 9: return 'September'
        case 10: return 'October'
        case 11: return 'November'
        case 12: return 'December'
    }
}

export const convertMonthShort = (month: any) => {
    switch (month){
        case 1 || '1': return 'Jan'
        case 2: return 'Feb'
        case 3: return 'Mar'
        case 4: return 'Apr'
        case 5: return 'May'
        case 6: return 'Jun'
        case 7: return 'Jul'
        case 8: return 'Aug'
        case 9: return 'Sep'
        case 10: return 'Oct'
        case 11: return 'Nov'
        case 12: return 'Dec'
    }
}