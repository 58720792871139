import { Card, CircularProgress } from '@material-ui/core';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import { createStyles, makeStyles, Theme, useTheme } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from "@material-ui/core/TableCell";
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Typography from '@material-ui/core/Typography';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { observer } from 'mobx-react-lite';
import React from 'react';
import { Line } from '../../cuba/entities/billing_Line';
import { bracketedNegativesFormat, bracketedNegativesFormatToCurrency } from '../../util/NumberFormatter';


const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        paper: {
            maxWidth: 500,
            display: 'flex'
        },
        root: {
            alignContent: 'center',
            display: 'block',
            alignItems: 'center'
        },
        warning: {
            borderColor: 'gray',
            borderTopWidth: 2,
            color: 'red',
            fontSize: 10
        }, // or bord
        tableBorders: {
            borderWidth: 1,
            borderColor: 'gray',
            borderStyle: 'solid',
            borderTopWidth: 10
        }, // or borderTop: '1px solid red'
        tableNoBorder: {
            borderWidth: 1,
            borderColor: 'red',
            borderStyle: 'solid',

        }, // or borderTop: '1px solid red'
        circle: {
            strokeLinecap: 'round',
            color: '#1a90ff',
            animationDuration: '550ms',

        },
        spinner: {
            color: '#1a90ff',
            animationDuration: '550ms',
        },
        details: {
            display: 'flex',
            justifyContent: 'center',
            alignContent: 'center',
            flexDirection: 'column',
            padding: theme.spacing(3),
        },
        content: {
            flex: '1 0 auto',
        },
        cover: {
            width: 151,
        },
        title: {
            fontSize: 14,
            textAlign: 'center'
        },
        controls: {
            alignContent: 'center',
            display: 'flex',
            alignItems: 'center',
            alignSelf: 'center',
            padding: theme.spacing(2),
            backgroundColor: theme.palette.background.paper,
            borderRadius: 30,

        },
        playIcon: {
            height: 50,
            width: 50,
            color: "textPrimary"
        },
        centerAlign: {
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: 'center',
            paddingTop: theme.spacing(2),
            paddingBottom: theme.spacing(1),
            paddingLeft: theme.spacing(2),
            paddingRight: theme.spacing(2),
            cursor: 'pointer'
        },
        palette: {
            secondary: {
                main: '#f44336',
            },
        },
        appBar: {
            background: 'white',
            position: '-webkit-sticky',
            top: 50,
            bottom: 20,
            zIndex: 500,
        },
        dateDescription: {
            fontSize: 14,
            textAlign: 'center'
        },

    }));

const CurrentSpend = observer((props: any) => {

    const classes = useStyles();
    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));
    const billingPeriodSpend = props.billingPeriodSpend
    const isLoading = props.isLoading
   

    function formatDateWithYear(date: Date | undefined) {
        const moment = require('moment');
        return moment(date).format('DD MMMM YYYY')
    }


    const handleClose = () => {
        props.setOpen(false)
    }




    return (
        <Dialog
            fullScreen={fullScreen}
            open={props.open}
            onClose={handleClose}
            aria-labelledby="responsive-dialog-title"

        >
            <DialogTitle id="responsive-dialog-title">{`Spend Overview`}

                <Typography className={classes.dateDescription} color="textSecondary" gutterBottom>
                    {formatDateWithYear(billingPeriodSpend.billingPeriod?.fromDate)} to {formatDateWithYear(billingPeriodSpend.billingPeriod?.toDate)}
                </Typography>
                <Box boxShadow={3}>
                    <Card color="default" className={classes.appBar}>
                        <Grid container className={classes.root} spacing={2}>
                            <Grid item xs={12}>
                                <Grid container justify="center" spacing={6}>

                                    <Grid key={'subtotal'} item>
                                        <Paper className={classes.paper} />
                                        <Typography variant="overline" display="block" gutterBottom align='center'>
                                            Subtotal
                      </Typography>
                                        <Typography variant="button" display="block" gutterBottom align='center'>
                                            {bracketedNegativesFormatToCurrency(billingPeriodSpend.subTotal)}
                                        </Typography>
                                    </Grid>
                                    <Grid key={'gst'} item>
                                        <Paper className={classes.paper} />
                                        <Typography variant="overline" display="block" gutterBottom align='center'>
                                            GST
                      </Typography>
                                        <Typography variant="button" display="block" gutterBottom align='center'>
                                            {bracketedNegativesFormatToCurrency(billingPeriodSpend.gst)}
                                        </Typography>
                                    </Grid>
                                    <Grid key={'total'} item>
                                        <Paper className={classes.paper} />
                                        <Typography variant="overline" display="block" gutterBottom align='center'>
                                            Total
                      </Typography>
                                        <Typography variant="button" display="block" gutterBottom align='center'>
                                            {bracketedNegativesFormatToCurrency(billingPeriodSpend.total)} {billingPeriodSpend.total < 0 ? ' CREDIT' : null}
                                        </Typography>
                                    </Grid>

                                </Grid>
                            </Grid>
                        </Grid>
                    </Card>
                </Box>
            </DialogTitle>
            <DialogContent>

                {isLoading ?
                    <CircularProgress
                        variant="indeterminate"
                        disableShrink
                        className={classes.spinner}
                        classes={{
                            circle: classes.circle,
                        }}
                        size={40}
                        thickness={4}
                        style={{ marginLeft: '40%' }}

                    />
                    :
                    <div>
                        <Table size="small" aria-label="spanning table">
                            <TableHead>
                                <TableRow >
                                    <TableCell>Description</TableCell>
                                    <TableCell>Meter</TableCell>
                                    <TableCell align="left">Qty.</TableCell>
                                    <TableCell align="left">Rate</TableCell>
                                    <TableCell align="left">Subtotal</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {billingPeriodSpend.billingPeriod?.lines?.map((line: Line) => (
                                    <TableRow key={line.id} hover={true}>
                                        <TableCell>{line.lineDescription}</TableCell>
                                        <TableCell>{line.meteringDescription}</TableCell>
                                        <TableCell align="left">{line.quantity}</TableCell>
                                        <TableCell align="left">{bracketedNegativesFormat(line.rate)}</TableCell>
                                        <TableCell align="left">{bracketedNegativesFormatToCurrency(line.quantity * line.rate)}</TableCell>
                                    </TableRow>
                                ))}

                            </TableBody>
                        </Table>
                    </div>
                }

            </DialogContent>
            <DialogActions>

                <Typography className={classes.warning} color="textSecondary" gutterBottom>
                    Note: This data could be incomplete and is subject to change
          </Typography>

                <Button autoFocus onClick={handleClose} color="primary">
                    OK
          </Button>
            </DialogActions>
        </Dialog>
    )
}
);



export default CurrentSpend;
