import DateFnsUtils from '@date-io/date-fns';
import { Card, Grid } from '@material-ui/core';
import Box from '@material-ui/core/Box';
import CardContent from '@material-ui/core/CardContent';
import IconButton from '@material-ui/core/IconButton';
import { makeStyles } from '@material-ui/core/styles';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import { KeyboardDatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import { observer } from 'mobx-react-lite';
import React, { useContext, useEffect } from 'react';
import { Area, AreaChart, Legend, ResponsiveContainer, Tooltip, XAxis, YAxis } from 'recharts';
import { StoreContext } from '../../../../../context/store-context';
import { ConvertPeriod } from '../../../../../util/utility';



export const UsageOverviewChart = observer(() => {
    const store = useContext(StoreContext);
    const classes = useStyles();


    useEffect(() => {
        if (!store.app.icpStore.meterReadsDate) {
            store.app.icpStore.updateMeterReadsChart(null)
        }
    }, [store.app.icpStore]);


    const gotoPreviousDay = () => {
        let date: Date | null | undefined = store.app.icpStore.meterReadsDate
        if (date) {
            date.setDate(date.getDate() - 1);
            store.app.icpStore.updateMeterReadsChart(date)
        }
    }

    const gotoNextDay = () => {
        let date: Date | null | undefined = store.app.icpStore.meterReadsDate
        if (date) {
            date.setDate(date.getDate() + 1);
            store.app.icpStore.updateMeterReadsChart(date)
        }
    }

    const handleDateChange = (date: Date | null) => {
        if (date) {
            store.app.icpStore.updateMeterReadsChart(date)
        }
    };

    const getChartColor = (channelData: string) => {
        if (channelData.endsWith('UN')) {
            return '#8884D8'
        }
        if (channelData.endsWith('EG')) {
            return '#82CA9D'
        }
        if (channelData.endsWith('CN')) {
            return '#c9a062'
        }
        else return '#ef86d5'
    }

    return (
        <Card className={classes.root}>
            <CardContent >
                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                    <Grid
                        justify="space-evenly" // Add it here :)
                        container
                    >
                        <Grid item
                            lg={2}
                            sm={2}
                            xs={2}
                        >
                            <IconButton aria-label="delete" color="primary" onClick={gotoPreviousDay}>
                                <ChevronLeftIcon />
                            </IconButton>
                        </Grid>
                        <Grid
                            item
                            lg={8}
                            sm={8}
                            xs={8}
                        >
                            <KeyboardDatePicker
                                margin="normal"
                                id="date-picker-dialog"
                                label="Meter Readings"

                                format="dd MMM"
                                value={store.app.icpStore.meterReadsDate}
                                onChange={handleDateChange}
                                KeyboardButtonProps={{
                                    'aria-label': 'change date',
                                }}
                            />
                        </Grid>
                        <Grid item
                            lg={2}
                            sm={2}
                            xs={2}>
                            <IconButton aria-label="delete" color="primary" onClick={gotoNextDay}>
                                <ChevronRightIcon />
                            </IconButton>
                        </Grid>
                    </Grid>
                    <Box display="flex" flexDirection="row" >
                        <ResponsiveContainer width="100%" minHeight={400}>
                                <AreaChart /* barCategoryGap='30%' */ height={400}
                                    data={store.app.icpStore.meterReadsGraphData?.data}
                                    margin={{ top: 0, right: 0, left: 0, bottom: 0 }}
                                    layout='horizontal'
                                    barCategoryGap={50}
                                >
                                    <XAxis
                                        dataKey={'period'}
                                        type='number'
                                        axisLine={true}
                                        tickCount={24}
                                        domain={[1, 48]}
                                        padding={{ left: 5, right: 5 }}
                                        allowDataOverflow={true}
                                        allowDuplicatedCategory={true}
                                        tickFormatter={(tick) => ConvertPeriod(tick)



                                        }
                                    />
                                    <YAxis orientation={'right'} type={'number'} width={30} />

                                    <Tooltip
                                        labelFormatter={(label) => 'Time period: ' + ConvertPeriod(label as number)}
                                        formatter={(kwh) => (kwh as number).toFixed(3) + 'kwh'}
                                        //cursor={{fill: '#f00'}}
                                    />
                                    <Legend />
                                    {store.app.icpStore.meterReadsGraphData?.channels.map((channel) => (
                                        <Area
                                            key={`line_${channel}`}
                                            dataKey={channel}
                                            stroke={getChartColor(channel)}
                                            fill={getChartColor(channel)}
                                            strokeWidth={3}
                                            legendType="rect"
                                            name={channel}
                                        //visibility={'hidden'} todo possible remove graphs
                                        >
                                        </Area>
                                    ))}

                                </AreaChart>
                        </ResponsiveContainer>

                    </Box>
                </MuiPickersUtilsProvider>
            </CardContent>

        </Card>
    );
})


const useStyles = makeStyles({
    root: {

        marginBottom: 12,
        marginTop: 12,
        height: '75vh'

    },
    bullet: {
        display: 'inline-block',
        margin: '0 2px',
        transform: 'scale(0.8)',
    },
    title: {
        fontSize: 18,
        textAlign: 'center'
    },
    dateDescription: {
        fontSize: 14,
        textAlign: 'center'
    },
    pos: {
        marginBottom: 12,
        marginTop: 25,
    },
    circle: {
        strokeLinecap: 'round',
        color: '#1a90ff',
        animationDuration: '550ms',

    },
});

export default UsageOverviewChart