import { Backdrop, CircularProgress, CssBaseline } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import FormControl from "@material-ui/core/FormControl";
import IconButton from "@material-ui/core/IconButton";
import InputLabel from "@material-ui/core/InputLabel";
import OutlinedInput from "@material-ui/core/OutlinedInput";
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Visibility from "@material-ui/icons/Visibility";
import VisibilityOff from "@material-ui/icons/VisibilityOff";
import clsx from "clsx";
import { observer } from 'mobx-react-lite';
import React, { useContext } from 'react';
import { StoreContext } from '../../context/store-context';


const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            width: '100%',
            maxWidth: 360,
            backgroundColor: theme.palette.background.paper,
        },
        paper: {
            width: '80%',
            maxHeight: 435,
        },
        margin: {
            margin: theme.spacing(1)
        },
        textField: {
            width: "25ch"
        },
        backdrop: {
            zIndex: theme.zIndex.drawer + 50,
            color: 'hsl(0, 100%, 48%)',
        },
    }),
);


export interface PasswordResetProps {
    open: boolean;
    canClose: boolean
    passwordResetTitle?: string
    passwordResetInfoString?: string | 'Your password must be at least 8 characters long, have at least one capital letter and contain at least one number.'

}


const ChangePasswordDialog = observer((props: PasswordResetProps) => {
    const store = useContext(StoreContext);
    const { open, canClose, passwordResetTitle, passwordResetInfoString, ...other } = props;
    const classes = useStyles();

    const [password, setPassword] = React.useState<string>('')
    const [confirmPassword, setConfirmPassword] = React.useState<string>('')
    const [submitEnabled, setSubmitEnabled] = React.useState<boolean>(false)
    const [passwordPassesRegexp, setPasswordPassesRegexp] = React.useState<boolean>(false)
    const [confirmPasswordPassesRegexp, setConfirmPasswordPassesRegexp] = React.useState<boolean>(false)
    const [showPassword, setShowPassword] = React.useState<boolean>(false)
    const [settingPassword, setSettingPassword] = React.useState<boolean>(false)
    const [isOpen, setIsOpen] = React.useState<boolean>(true)

    //const strongRegex = new RegExp("^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,})");
    //const mediumRegex = new RegExp("^(((?=.*[a-z])(?=.*[A-Z]))|((?=.*[a-z])(?=.*[0-9]))|((?=.*[A-Z])(?=.*[0-9])))(?=.{6,})");

    //8 chars + minimum of 1 upper, 1 lower and 1 number
    const customRegex = new RegExp("^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.{8,})");

   React.useEffect(()=>{
        const checkPasswords = () => {
            if (passwordPassesRegexp && confirmPasswordPassesRegexp && password === confirmPassword) {
                setSubmitEnabled(true)
            } else {
                if (submitEnabled) {
                    setSubmitEnabled(false)
                }
            }
        }
        checkPasswords()        
},[password, confirmPassword, confirmPasswordPassesRegexp, passwordPassesRegexp, submitEnabled])

    const handlePasswordChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setPassword(event.target.value);
        if (customRegex.test(event.target.value)) {
            setPasswordPassesRegexp(true)
        } else {
            if (passwordPassesRegexp) {
                setPasswordPassesRegexp(false)
            }
        }
        
    }

    const handleConfirmPasswordChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setConfirmPassword(event.target.value);
        if (customRegex.test(event.target.value)) {
            setConfirmPasswordPassesRegexp(true)
        } else {
            if (confirmPasswordPassesRegexp) {
                setConfirmPasswordPassesRegexp(false)
            }
        }
        
    }

    const handleClickShowPassword = () => {
        setShowPassword(!showPassword)
    };

    const handleSubmit = () => {
        setSettingPassword(true)
        store.app.cubaRest?.invokeService<boolean>('billing_AccountService', 'changeAccountPassword', { account: store.app.accountStore.account, password: password })
            .then((res) => {
                if (res === true || 'true') {
                    store.app.accountStore.account!.changePasswordAtNextLogon = false
                    store.app.publishSnackbarAlert('Password Changed', 'success')
                }
            }).finally(() => {
                setSettingPassword(false)
            })
    }

    const handleMouseDownPassword = (
        event: React.MouseEvent<HTMLButtonElement>
    ) => {
        event.preventDefault();
    };

    return (
        <div>
            <CssBaseline />
            <Dialog
                disableBackdropClick
                disableEscapeKeyDown
                maxWidth="xs"
                //onEntering={handleEntering}
                aria-labelledby="confirmation-dialog-title"
                open={isOpen && open}
                {...other}
            >
                <DialogTitle id="confirmation-dialog-title">{props.passwordResetTitle ? props.passwordResetTitle : 'Set Password'}</DialogTitle>
                <DialogContent dividers>
                    <Typography variant="subtitle1" gutterBottom>
                        {props.passwordResetInfoString ?
                            props.passwordResetInfoString
                            :
                            'Your password must be at least 8 characters long, have at least one capital letter and contain at least one number.'
                        }
                    </Typography>
                    <FormControl
                        className={clsx(classes.margin, classes.textField)}
                        variant="outlined"
                    >
                        <InputLabel htmlFor="password">
                            Password
                    </InputLabel>
                        <OutlinedInput
                            id="password"
                            type={showPassword ? "text" : "password"}
                            value={password}
                            onChange={handlePasswordChange}
                            labelWidth={70}
                            color={passwordPassesRegexp ? 'secondary' : 'primary'}
                        />
                    </FormControl>
                    <FormControl
                        className={clsx(classes.margin, classes.textField)}
                        variant="outlined"
                    >
                        <InputLabel htmlFor="confirm-password">
                            Confirm Password
                    </InputLabel>
                        <OutlinedInput
                            id="confirm-password"
                            type={showPassword ? "text" : "password"}
                            value={confirmPassword}
                            onChange={handleConfirmPasswordChange}
                            labelWidth={70}
                            color={confirmPasswordPassesRegexp ? 'secondary' : 'primary'}
                        />
                    </FormControl>
                    <IconButton
                        aria-label="toggle password visibility"
                        onClick={handleClickShowPassword}
                        onMouseDown={handleMouseDownPassword}
                        edge="end"
                    >
                        {showPassword ? <Visibility /> : <VisibilityOff />}
                    </IconButton>
                </DialogContent>
                <DialogActions>
                    {canClose ?
                        <Button onClick={()=>setIsOpen(false)} color="primary" disabled={!canClose}>
                            Cancel
                    </Button>
                        : null}
                    <Button onClick={handleSubmit} color="primary" disabled={!submitEnabled}>
                        Set Password
        </Button>
                </DialogActions>
            </Dialog>
            <Backdrop className={classes.backdrop} open={settingPassword} >
                <CircularProgress color="inherit" />
            </Backdrop>
        </div>
    );

})


export default ChangePasswordDialog