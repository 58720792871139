import { Grid } from '@material-ui/core';
import Box from '@material-ui/core/Box';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import OfflineBoltIcon from '@material-ui/icons/OfflineBolt';
import { observer } from 'mobx-react-lite';
import React, { useContext } from 'react';
import { StoreContext } from '../../../../context/store-context';
import { Network } from '../../../../cuba/entities/base/ecos_Network';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: 'flex',
      alignContent: 'space-between'
    },
    details: {
      display: 'flex',
      flexDirection: 'column',
    },
    content: {
      //flex: '1 0 auto',
    },
    description: {
      padding: theme.spacing(2),

    },

    cover: {
      display: 'block',
      width: 130,
      height: 130,     // as an example I am modifying width and height
      alignItems: 'center',
      justifyContent: 'center',

    },
    /*  controls: {
       display: 'flex',
       alignItems: 'center',
       paddingLeft: theme.spacing(1),
       paddingBottom: theme.spacing(1),
     }, */
    playIcon: {
      height: 38,
      width: 38,
    },
    icon: {
      height: 20,
      width: 20,
      color: 'primary'
    },
    controls: {
      alignContent: 'center',
      display: 'flex',
      padding: theme.spacing(2),
      backgroundColor: theme.palette.background.paper,
      borderRadius: 25,
      //width:'100%'

    },
    typo: {
      padding: 1
    },
    logoImage: {
      display: 'flex',
      justifyContent: 'center',
    }
  }),

);

const NoPowerPage = observer(() => {
  const classes = useStyles();
  const store = useContext(StoreContext);
  const [network, setNetwork] = React.useState<Network>();

  React.useEffect(() => {
    store.app.cubaRest?.query('ecos_Network', 'getNetwork',
      { participantIdentifier: store.app.icpStore.currentIcpList?.networkParticipantIdentifier })
      .then((networks) => {
        setNetwork(networks[0] as Network)
      })
  }, [store.app.cubaRest, store.app.icpStore.currentIcpList]);


  return (

    <div>
      <Grid
        container
        spacing={2}
        direction="column"
        alignItems="center"
        justify="center"
        style={{ minHeight: '80vh' }}
      >

        <Grid item
          sm={12}
          md={12}
          lg={6}

        >
          <div className={classes.description} >

            <Typography gutterBottom variant="h3" component="h2">
              <OfflineBoltIcon fontSize="large" color='primary' style={{ fontSize: 40 }} /> No Power?
          </Typography>
            <Typography variant="body1" color="textSecondary" className={classes.typo}>
              If you have an interruption to your power supply, you will need to get in touch with your network.
        </Typography>
            <Typography variant="body1" color="textSecondary" className={classes.typo}>
              They will endeavour to resolve any issue for you as soon as possible.
        </Typography>
          </div>

          <Grid item
            sm={12}
            md={12}
            lg={12}    >
            <Grid
              container
              spacing={2}
              direction="row"
              alignItems="center"
              justify="center"
            >
              <Grid item
                xs={12}
                sm={6}
                md={6}
                lg={6}
              >
                <Card>
                  <CardContent className={classes.content}>
                    <Box
                      display={'block'}

                    >
                      <Typography component="h5" variant="h5" align='center'>
                        {network?.name}
                      </Typography>
                      <Typography variant="subtitle1" color="textSecondary" align='center'>
                        {network?.id}
                      </Typography>
                      <Box
                        display={'block'}
                        alignContent='center'
                        alignItems='center'
                        alignSelf='center'
                        justifyContent='center'

                      >
                        <div>
                          {network?.outagesContact?.phone ?
                            <a href={`tel:${network?.outagesContact?.phone}`}>
                              <Typography variant="h6" color="primary" align='center'>
                                {network?.outagesContact?.phone}
                              </Typography>
                            </a>
                            : null}
                          {network?.outagesContact?.email ?
                            <Typography variant="overline" color="textSecondary" align='center'>
                              {network?.outagesContact?.email}
                            </Typography>
                            : null}
                          {network?.outagesContact?.website ?

                            <Typography variant="subtitle1" color="textSecondary" align='center'>
                              {network?.outagesContact?.website}
                            </Typography>
                            : null}
                        </div>
                     {/*    <Box className={classes.logoImage} >
                          <img src={process.env.PUBLIC_URL + `/images/networks/${network?.id}.png`} width={100} height={100} alt='network logo' />
                        </Box> */}

                      </Box>
                    </Box>
                  </CardContent>
                </Card>
              </Grid>
              <Box className={classes.controls} boxShadow={3}>
                <img src={`./images/networks/${network?.id}.png`} width={100} height={100} alt='network logo' />
              </Box>

            </Grid>
          </Grid>
        </Grid>

      </Grid>
    </div>

  );
})

export default NoPowerPage
