import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import Container from '@material-ui/core/Container';
import CssBaseline from '@material-ui/core/CssBaseline';
import Grid from '@material-ui/core/Grid';
import Link from '@material-ui/core/Link';
import { makeStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import LockOpenIcon from '@material-ui/icons/LockOpen';
import { Form, Formik } from 'formik';
import { observer } from 'mobx-react-lite';
import React, { useContext } from 'react';
import { StoreContext } from '../../context/store-context';
import routes from '../../routing/routes';



interface Values {
    emailOrAccount: string
}

const useStyles = makeStyles((theme) => ({
    paper: {
        marginTop: theme.spacing(8),
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    },
    avatar: {
        margin: theme.spacing(1),
        backgroundColor: theme.palette.secondary.main,
    },
    form: {
        width: '100%', // Fix IE 11 issue.
        marginTop: theme.spacing(1),
    },
    submit: {
        margin: theme.spacing(3, 0, 2),
    },
    circle: {
        strokeLinecap: 'round',
        color: '#1a90ff',
        animationDuration: '550ms',

    },
    spinner: {
        color: '#1a90ff',
        animationDuration: '550ms',
    },
    title: {
        fontSize: 18,
        textAlign: 'center'
    },
}));

export const ForgotPasswordPage = observer(() => {
    const classes = useStyles();
    const store = useContext(StoreContext);
    const [passwordReset, setPasswordReset] = React.useState<boolean>(false)
    const [sendingPasswordResetRequest, setSendingPasswordResetRequest] = React.useState<boolean>(false)

    const handleSubmit = (values: Values) => {
        setSendingPasswordResetRequest(true)
        store.app.cubaRest?.invokeService('billing_AccountService', 'generateResetToken', { accountNumberOrEmail: values.emailOrAccount }).then((res) => {
            console.log(res)
        }).finally(() => {
            console.log()
            setSendingPasswordResetRequest(false)
            setPasswordReset(true)
        })
    }

    const gotoLogin = () => {
        store.router.goTo(routes.login)
    }
    

    if (sendingPasswordResetRequest) {
        return (
            <Container component="main" maxWidth="xs">
                <CssBaseline />
                <div className={classes.paper}>
                    <CircularProgress />
                    <Typography style={{ padding: 12 }}>
                        Sending request.
                </Typography>
                </div>
            </Container>
        )
    } else {
        return (
            <Container component="main" maxWidth="xs">
                <CssBaseline />
                <div className={classes.paper}>
                    <Avatar className={classes.avatar}>
                        <LockOpenIcon />
                    </Avatar>
                    {passwordReset ?
                        <div>
                            <Typography component="h1" variant="h3" align={'center'} style={{ padding: 12 }}>
                                Password Reset Request Sent
                    </Typography>
                            <Typography style={{ padding: 12 }}>
                                If you inserted a valid email or account number you should receive an email with a password reset link within the next 10 minutes.
                    </Typography>
                            <Typography style={{ padding: 12 }}>
                                Follow the instuctions therein to set your password.
                    </Typography>
                            <Typography>

                            </Typography>
                        </div>
                        :
                        <div>
                            <Typography component="h1" variant="h3" align={'center'} style={{ padding: 12 }}>
                                Password Set/Reset
                </Typography>
                            <Typography style={{ padding: 12 }}>
                                If you have an account with us please insert your account number or email address then click submit.
                        </Typography>
                            <Typography style={{ padding: 12 }} variant="overline">
                                A password reset link will be sent to your email.
                        </Typography>
                            <Formik
                                initialValues={{ emailOrAccount: "" }}
                                onSubmit={values => {
                                    handleSubmit(values)
                                }}>{({ values, handleChange, handleBlur }) => (
                                    <Form>
                                        <TextField
                                            variant="outlined"
                                            margin="normal"
                                            required
                                            fullWidth
                                            id="emailOrAccount"
                                            label="Email or Account Number"
                                            name="emailOrAccount"
                                            autoFocus
                                            value={values.emailOrAccount}
                                            onChange={handleChange} onBlur={handleBlur}

                                        />
                                        <Button
                                            type="submit"
                                            fullWidth
                                            variant="contained"
                                            color="primary"
                                            className={classes.submit}
                                        >
                                            Submit
                                    </Button>
                                    </Form>

                                )}
                            </Formik>
                        </div>
                    }
                    <Grid container>
                        <Grid item xs>
                        <Link style={{cursor:'pointer'}} variant="body2" onClick={gotoLogin}>
                        Back to login
                                            </Link>
                           
                        </Grid>
                        <Grid item>
                            <Link href="#" variant="body2">
                                {"No Account? Sign Up"}
                            </Link>
                        </Grid>
                    </Grid>
                </div>
            </Container>
        );
    }
}
)

export default ForgotPasswordPage