import { action, autorun, observable } from 'mobx';
import { Account } from '../../../cuba/entities/billing_Account';
import { Balances } from '../../../cuba/entities/billing_Balances';
import AppStore from '../app-store';

class AccountStore {

  @observable
  account?: Account | null = undefined

  @observable
  balances?: Balances | null | undefined

  @observable
  unreadMessages?: number 

  public appStore?: AppStore

  constructor(appStore: AppStore) {
    this.appStore = appStore

    /* Auto populate data once account is logged in */
    autorun(() => {
      if (this.account) {
        this.balances = this.account.balances
        this.unreadMessages = 0
      }
    })
  }

  @action
  updateAccount() {
    this.appStore?.cubaRest?.loadEntity('billing_Account', this.account?.id, {view:'account-rest-view'})
    .then((acc) => {
      this.account = acc as Account
    })
  }

  setBalances = action((balances: Balances | undefined) => {
    this.balances = balances;
  });
}
export default AccountStore
