import { Box, TablePagination } from '@material-ui/core';
import Backdrop from '@material-ui/core/Backdrop';
import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import Dialog, { DialogProps } from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import IconButton from '@material-ui/core/IconButton';
import Paper from '@material-ui/core/Paper';
import { createStyles, makeStyles, Theme, useTheme } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import VisibilityIcon from '@material-ui/icons/Visibility';
import { observer } from 'mobx-react-lite';
import React, { useContext, useEffect } from 'react';
import { Document, Page } from 'react-pdf/dist/esm/entry.webpack';
import { ResponsiveContainer } from 'recharts';
import { StoreContext } from '../../../../context/store-context';
import { BillingPeriod } from '../../../../cuba/entities/billing_BillingPeriod';
import { Invoice } from '../../../../cuba/entities/billing_Invoice';

//pdfjs.GlobalWorkerOptions.workerSrc = `https://cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;
//pdfjs.GlobalWorkerOptions.workerSrc = `https://cdnjs.cloudflare.com/ajax/libs/pdf.js/2.0.550/pdf.worker.js`;


const useStyles = makeStyles((theme: Theme)=>

createStyles({
    root: {
        width: '100%',
        display: 'block',
        justifyContent: 'center',
        alignItems: 'center',
        padding:5

    },
    backdrop: {
        zIndex: theme.zIndex.drawer + 1,
        color: '#fff',
      },
    tableContainer: {
        height: '80vh'
    },
    circle: {
        strokeLinecap: 'round',
        color: '#1a90ff',
        animationDuration: '550ms',
    },
    tableRow: {
        "&:hover": {
            backgroundColor: "light-gray !important",
            cursor: "pointer"
        }
    },
    pdfViewIcon: {
        width: 40,
        height: 40,
    },

}),
);


const InvoicesTable = observer(() => {

    const theme = useTheme();
    const classes = useStyles();
    const store = useContext(StoreContext);
    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(10);
    const [invoiceData, setInvoiceData] = React.useState<any>()   
    const [numPages, setNumPages] = React.useState<any>(0);
    const [pageNumber, setPageNumber] = React.useState(1);
    const [open, setOpen] = React.useState(false);
    const [scroll] = React.useState<DialogProps['scroll']>('paper');
    const [backDropOpen, setBackDropOpen] = React.useState<boolean>(false);
    const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));

    useEffect(() => {
        if (!store.app.invoiceStore.invoices) {
            store.app.invoiceStore.fetchInvoices()
        }
        return () => {   
        }
    }, [store.app, store.app.cubaRest]);

    const downloadPdf = React.useCallback(theInvoice => {
        setBackDropOpen(true)
        store.app.cubaRest?.invokeService<any>('billing_InvoiceService', 'generateInvoiceDocument', { invoice: theInvoice })
            .then((response: any) => {             
                setInvoiceData({ data: JSON.parse(response).content });
                setPageNumber(1)
                setOpen(true)
            }).finally(()=>{
                setBackDropOpen(false)
            })
    }, [store.app.cubaRest])

    const handleNextPage = () => {
        if (pageNumber === (numPages._pdfInfo.numPages)) {
            setPageNumber(1)
        } else {
            setPageNumber(pageNumber + 1)
        }
    }

    interface Column {
        id: 'view' | 'invoiceNumber' | 'invoiceType' | 'billingPeriod' | 'subTotal' | 'gst' | 'total' | 'Download';
        label: string;
        minWidth?: number;
        align?: 'left' | 'right' | 'center';
        format?: (value: number) => string;
    }

    const columns: Column[] = [
        { id: 'view', label: 'View', minWidth: 50, align: 'center' },
        //{ id: 'invoiceNumber', label: 'Invoice number', minWidth: 100 },
        { id: 'invoiceType', label: 'Invoice type', minWidth: 100 },
        { id: 'billingPeriod', label: 'Billing period', minWidth: 200 },
        {
            id: 'subTotal',
            label: 'Subtotal',
            minWidth: 100,
            align: 'right',
            format: (value: number) => value.toLocaleString('en-US'),
        },
        {
            id: 'gst',
            label: 'GST',
            minWidth: 100,
            align: 'right',
            format: (value: number) => value.toLocaleString('en-US'),
        },
        {
            id: 'total',
            label: 'Total',
            minWidth: 100,
            align: 'right',
            format: (value: number) => value.toFixed(2),
        },

    ];

    const currencyFormatter = (value: number) => {
        return value.toFixed(2)
    }

    const handleChangePage = (event: unknown, newPage: number) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
        setRowsPerPage(+event.target.value);
        setPage(0);
    };

    const billingPeriodFormatter = (billingPeriod: BillingPeriod) => {
        const moment = require('moment');
        return moment(billingPeriod.fromDate).format('DD MMM YYYY') + ' - ' + moment(billingPeriod.toDate).format('DD MMM YYYY')
    }

    const handleClose = () => {
        setOpen(false);
    };

    function onDocumentLoadSuccess(numPages: any) {
        setNumPages(numPages);
    }
    return (
        <div>            
            <Paper className={classes.root}>
           
                <TableContainer className={classes.tableContainer} >    
                
                    <Table stickyHeader aria-label="sticky table">
                 
                        <TableHead>
                            <TableRow>
                                {columns.map((column) => (
                                    <TableCell
                                        key={column.id}
                                        align={column.align}
                                        style={{ minWidth: column.minWidth }}
                                    >
                                        {column.label}
                                    </TableCell>
                                ))}
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {store.app.invoiceStore.invoices?.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((invoice: Invoice) => {
                                return (
                                    <TableRow className={classes.tableRow} hover role="checkbox" tabIndex={-1} key={invoice.id} >
                                        <TableCell key={'download'} align={'center'} >
                                            <IconButton color="primary" aria-label="upload picture" component="span" key={invoice.id} onClick={() => downloadPdf(invoice)}>
                                                <VisibilityIcon className={classes.pdfViewIcon}>VIEW</VisibilityIcon>
                                            </IconButton>
                                        </TableCell>
                                        <TableCell key={'invoiceType'} align={'left'} width={170}>
                                            {invoice.invoiceType==='ACC_PAY'?'CREDIT':'DEBIT'}
                                        </TableCell>
                                        <TableCell key={'billingPeriod'} align={'left'}>
                                            {billingPeriodFormatter(invoice.billingPeriod!)}
                                        </TableCell>
                                        <TableCell key={'subTotal'} align={'right'}>
                                        {invoice.invoiceType==='ACC_PAY'?`(${currencyFormatter(invoice.subTotal)})`:currencyFormatter(invoice.subTotal)}
                                        </TableCell>
                                        <TableCell key={'gst'} align={'right'}>                                          
                                            {invoice.invoiceType==='ACC_PAY'?`(${currencyFormatter(invoice.gst)})`:currencyFormatter(invoice.gst)}
                                        </TableCell>
                                        <TableCell key={'total'} align={'right'}>
                                        <strong> {invoice.invoiceType==='ACC_PAY'?`(${currencyFormatter(invoice.total)})`:currencyFormatter(invoice.total)}</strong>
                                         
                                        </TableCell>

                                    </TableRow>
                                );
                            })}
                        </TableBody>
                    </Table>
                </TableContainer>
                <TablePagination
                    rowsPerPageOptions={[10, 25, 100]}
                    component="div"
                    count={store.app.invoiceStore.invoices?.length ? store.app.invoiceStore.invoices.length : 0}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    onChangePage={handleChangePage}
                    onChangeRowsPerPage={handleChangeRowsPerPage}
                />
            </Paper>
            <Dialog
                open={open}
                onClose={handleClose}
                scroll={scroll}
                aria-labelledby="scroll-dialog-title"
                aria-describedby="scroll-dialog-description"
                fullScreen={fullScreen}            
                maxWidth={'lg'}
                fullWidth={true}
            >
                <DialogTitle id="scroll-dialog-title">Invoice Page: {pageNumber} </DialogTitle>
                <DialogContent dividers={scroll === 'paper'}>
                    <Document
                        file={invoiceData}
                        onLoadSuccess={onDocumentLoadSuccess}
                        onLoadError={console.error}
                    >
                        <Box boxShadow={3} >
                            <ResponsiveContainer>
                                <Page pageNumber={pageNumber} />
                            </ResponsiveContainer>
                        </Box>
                    </Document>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose} color="primary">
                        Cancel
          </Button>
                    <Button color="primary" disableElevation onClick={handleNextPage}>
                        Next Page
            </Button>
                </DialogActions>
            </Dialog>

    <Backdrop className={classes.backdrop} open={backDropOpen} onClick={handleClose}>
        <CircularProgress color="inherit" />
      </Backdrop>
        </div>
    );
}
)
export default InvoicesTable
