import { action } from 'mobx';
import { RouterStore } from 'mobx-router';
import AppStore from './stores/app-store';

export class RootStore {

  
  public router: RouterStore<RootStore>;
  public app: AppStore;
  

  constructor() {   
    this.router = new RouterStore<RootStore>(this);
    this.app = new AppStore();
    

    //goto login page after refresh screen
    if (window.performance) {
      if (performance.navigation.type === 1) {
        //this.router.goTo(routes.login)        
      } 
    }    
  }

  handleError = action((error: TypeError) => {
  console.log("app-store: handleCubaError")
   switch(error.message){
     case 'Failed to fetch' : store.app.publishSnackbarAlert(error.message, 'warning')
   }
  });


}

const store = new RootStore();

export default store;