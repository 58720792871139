import { BaseUuidEntity } from "./base/sys$BaseUuidEntity";
export class AccountHhrImportExportView extends BaseUuidEntity {
  static NAME = "billing_AccountHhrImportExportView";
  account?: any | null;
  exportedVolume?: any | null;
  icpIdentifier?: string | null;
  importedVolume?: any | null;
  readDate?: any | null;
}
export type AccountHhrImportExportViewViewName =
  | "_base"
  | "_local"
  | "_minimal";
export type AccountHhrImportExportViewView<
  V extends AccountHhrImportExportViewViewName
> = V extends "_base"
  ? Pick<
      AccountHhrImportExportView,
      | "id"
      | "account"
      | "exportedVolume"
      | "icpIdentifier"
      | "importedVolume"
      | "readDate"
    >
  : V extends "_local"
  ? Pick<
      AccountHhrImportExportView,
      | "id"
      | "account"
      | "exportedVolume"
      | "icpIdentifier"
      | "importedVolume"
      | "readDate"
    >
  : never;
