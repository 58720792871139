import { Box } from '@material-ui/core';
import Avatar from '@material-ui/core/Avatar';
import { deepOrange } from '@material-ui/core/colors';
import Divider from '@material-ui/core/Divider';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import AccountBoxIcon from '@material-ui/icons/AccountBox';
import AccountBoxOutlinedIcon from '@material-ui/icons/AccountBoxOutlined';
import DescriptionIcon from '@material-ui/icons/Description';
import DescriptionOutlinedIcon from '@material-ui/icons/DescriptionOutlined';
import EqualizerIcon from '@material-ui/icons/Equalizer';
import HomeIcon from '@material-ui/icons/Home';
import HomeOutlinedIcon from '@material-ui/icons/HomeOutlined';
import PowerOffIcon from '@material-ui/icons/PowerOff';
import PowerOffOutlinedIcon from '@material-ui/icons/PowerOffOutlined';
import { observer } from 'mobx-react-lite';
import React, { useContext } from 'react';
import { StoreContext } from '../../../context/store-context';
import routes from '../../../routing/routes';


const useStyles = makeStyles((theme) => ({
  root: {
    display: 'block',
    paddingTop: theme.spacing(2),
  },

  listItem: {
    fontSize: 35,
  },
  avatar: {
    color: theme.palette.getContrastText(deepOrange[500]),
    backgroundColor: deepOrange[500],
    alignSelf: 'center'
  },
  selectedIcon:{
    color:'primary',
    fontSize:"inherit"
  },  
  notSelectedIcon:{
    color:'inherit',
    fontSize:"inherit"
  }
}));

interface Props { drawerOpen: Boolean | false};

const Menu = observer((props:Props) => {
  
  const store = useContext(StoreContext);
  const { router: { params, goTo } } = store;
  const classes = useStyles();


  if (params?.tab === null) {
    goTo(routes.dashboard, { tab: 'home' })
  }

  const gotoHome = () => {
    goTo(routes.dashboard, { tab: 'home' })
  };
  const gotoProfile = () => {
    goTo(routes.dashboard, { tab: 'profile' })
  };
  const gotoIcp = () => {
    goTo(routes.dashboard, { tab: 'icp' })
  };

  const gotoInvoicing = () => {
    goTo(routes.dashboard, { tab: 'invoicing' })
  };

/*   const goToMessaging = () => {
    goTo(routes.dashboard, { tab: 'messaging' })
  }; */

  const gotoNoPower = () => {
    goTo(routes.dashboard, { tab: 'no-power' })
  };


  return (
    <div className={classes.root}>
      <Box p={2} 
      display={'block'} alignContent={'center'} flexDirection={'column'}>
        <Avatar className={classes.avatar}>{store.app.accountStore.account?.firstName?.charAt(0)}{store.app.accountStore.account?.lastName?.charAt(0)}</Avatar>
         {props.drawerOpen?
         <Typography component="p" color="inherit" noWrap >
          {store.app.accountStore.account?.firstName} {store.app.accountStore.account?.lastName} 
        </Typography> 
        :null   
        }

      </Box>
      <Divider />
      <List>
        <ListItem button key={'Home'} onClick={gotoHome} className={classes.listItem}>
        <ListItemIcon >
            {params?.tab === 'home' ?
            <HomeIcon className={classes.selectedIcon} color='primary'/>
            :
            <HomeOutlinedIcon className={classes.notSelectedIcon}/>
            }            
          </ListItemIcon>         
          <ListItemText primary={'Home'} />
        </ListItem>
     {/*    <ListItem button key={'Speed'} onClick={gotoHome} className={classes.listItem}>
        <ListItemIcon >
            {params?.tab === 'speed' ?
            <SpeedIcon className={classes.selectedIcon} color='primary'/>
            :
            <SpeedIcon className={classes.notSelectedIcon}/>
            }            
          </ListItemIcon>         
          <ListItemText primary={'Real Time'} />
        </ListItem> */}
       
        <ListItem button key={'ICP'} onClick={gotoIcp} className={classes.listItem}>
          <ListItemIcon >
            <EqualizerIcon
              fontSize="inherit"
              color={params?.tab === 'icp' ? 'primary' : 'inherit'} />
          </ListItemIcon>
          <ListItemText primary={'ICP'} />
        </ListItem>
        <ListItem button key={'Invoicing'} onClick={gotoInvoicing} className={classes.listItem} >
        <ListItemIcon >
            {params?.tab === 'invoicing' ?
            <DescriptionIcon className={classes.selectedIcon} color='primary'/>
            :
            <DescriptionOutlinedIcon className={classes.notSelectedIcon}/>
            }            
          </ListItemIcon>
          <ListItemText primary={'Invoicing'} />
        </ListItem>
      {/*   <ListItem button key={'Messaging'} onClick={goToMessaging} className={classes.listItem} >
        <ListItemIcon >
            {params?.tab === 'messaging' ?
            <EmailIcon className={classes.selectedIcon} color='primary'/>
            :
            <EmailOutlinedIcon className={classes.notSelectedIcon}/>
            }            
          </ListItemIcon>          
          <ListItemText primary={'Messaging'} />
        </ListItem> */}
        <ListItem button key={'NoPower'} onClick={gotoNoPower} className={classes.listItem}>
          <ListItemIcon >
            {params?.tab === 'no-power' ?
            <PowerOffIcon className={classes.selectedIcon} color='primary'/>
            :
            <PowerOffOutlinedIcon className={classes.notSelectedIcon}/>
            }            
          </ListItemIcon>
          <ListItemText primary={'Power Outage?'} />
        </ListItem>
        <ListItem button key={'Profile'} onClick={gotoProfile} className={classes.listItem}>
        <ListItemIcon >
            {params?.tab === 'profile' ?
            <AccountBoxIcon className={classes.selectedIcon} color='primary'/>
            :
            <AccountBoxOutlinedIcon className={classes.notSelectedIcon}/>
            }            
          </ListItemIcon>
          <ListItemText primary={'My Details'} />
        </ListItem>
      </List>
    </div>
  )

})

export default Menu