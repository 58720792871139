
import { createStyles, makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import { observer } from 'mobx-react-lite';
import React, { useContext, useEffect } from "react";
import { Cell, Pie, PieChart, ResponsiveContainer } from "recharts";
import { StoreContext } from '../../../../../../context/store-context';

//const data = [{ name: "imported", value: 50 }, { name: "exported", value: 4 }];

const moment = require('moment');

const importedColor = "#0EA9E1" //'#118cd8'//
const exportedColor = "#65e03c" //'#14c823'//

const useStyles = makeStyles(() =>
  createStyles({
    root: {
      alignContent: 'center',
      display:'block',
      alignItems:'center'

  },
    title: {
      fontSize: 14,
      textAlign: 'center'
    },
  }),
);

export const UsageOverviewChart = observer(() => {
  const store = useContext(StoreContext);
  const classes = useStyles();
  //const [data, setData] = React.useState<BillingPeriodTotalConsumption[]>();


  useEffect(() => {
    if (store.app.contractStore.currentBillingPeriodSpend) {
      store.app.contractStore.updateBillingPeriodConsumptionTotals()      
    }

    return () => {
    }
  }, [store.app.contractStore, store.app.contractStore.currentBillingPeriodSpend]);

  const getBillingPeriodString = () => {
    return ` ${moment(store.app.contractStore.currentBillingPeriodSpend?.billingPeriod?.fromDate).format('DD MMM')} to ${moment(store.app.contractStore.currentBillingPeriodSpend?.billingPeriod?.toDate).format('DD MMM, yyyy')}`
  }


  if (store.app.contractStore.billingPeriodTotalConsumptions) {
    return (
      <div className={classes.root}>               
       <Typography component="h5" variant="h5" align='center'>
              Your usage so far...
          </Typography>
          <Typography className={classes.title} color="textSecondary" gutterBottom>
              for period {getBillingPeriodString()}
            </Typography>  
            <div style={{overflow:'auto'}}></div>
              <ResponsiveContainer minWidth={300} height={200} >                
                <PieChart>
                  <Pie
                    dataKey="volume"
                    data={store.app.contractStore.billingPeriodTotalConsumptions ? store.app.contractStore.billingPeriodTotalConsumptions : undefined}
                    cx="50%"
                    cy="50%"
                    innerRadius={30}
                    outerRadius={70}
                    labelLine={false}                              
                    label={({
                      cx,
                      cy,
                      midAngle,
                      innerRadius,
                      outerRadius,
                      value,
                      index
                    }) => {
                      const RADIAN = Math.PI / 180;
                      // eslint-disable-next-line
                      const radius = 8 + (innerRadius as number) + ((outerRadius as number) - (innerRadius as number));
                      // eslint-disable-next-line
                      const x = (cx as number) + (radius as number) * Math.cos(-(midAngle as number) * RADIAN);
                      // eslint-disable-next-line
                      const y = (cy as number) + (radius as number) * Math.sin(-(midAngle as number) * RADIAN);
                      return (
                        
                        <text
                          x={x}
                          y={y}
                          fill={store.app.contractStore.billingPeriodTotalConsumptions ? store.app.contractStore.billingPeriodTotalConsumptions[(index as number)].flowDirection === 'EXTRACT' ? importedColor : exportedColor : undefined}
                          fontSize={20}
                          fontWeight={500}
                          textAnchor={x > (cx as number) ? "start" : "end"}
                          dominantBaseline="central"
                        >                         
                        {value.toFixed(0)}kwh                        
                        </text>
                      );
                    }}
                  >
                    {
                      store.app.contractStore.billingPeriodTotalConsumptions?.map((entry, index) => (
                        <Cell key={`cell-${index}`} fill={entry.flowDirection === 'EXTRACT' ? importedColor : exportedColor} />
                      ))
                    }
                  </Pie>
                </PieChart>
               
              </ResponsiveContainer>      
              </div>    
    )
  } else {
    return null
  }
})

export default UsageOverviewChart