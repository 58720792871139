import { Box, Card, CircularProgress } from '@material-ui/core';
import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import Link from '@material-ui/core/Link';
import { makeStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import { Form, Formik } from 'formik';
import { observer } from 'mobx-react-lite';
import React, { useContext, useEffect } from 'react';
import { StoreContext } from '../../context/store-context';
import PWAInstallPrompt from '../../pwa/PWAInstallPrompt';
import routes from '../../routing/routes';


interface Values {
    login: string
    password: string
}

const useStyles = makeStyles((theme) => ({
    paper: {
        //marginTop: theme.spacing(8),
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        //backgroundColor:'gray',
        height:'95vh'
    },
    avatar: {
        margin: theme.spacing(1),
        backgroundColor: theme.palette.secondary.main,
    },
    form: {
        width: '100%', // Fix IE 11 issue.
        marginTop: theme.spacing(1),
    },
    submit: {
        margin: theme.spacing(3, 0, 2),        
    },
    circle: {
        strokeLinecap: 'round',
        color: '#1a90ff',
        animationDuration: '550ms',

    },
    spinner: {
        color: '#1a90ff',
        animationDuration: '550ms',
    },
    title: {
        fontSize: 18,
        textAlign: 'center'
    },
    hover:{
      
    },

    version:{
        position: 'fixed',
        bottom:0,
        right:0
    }
}));

export const Login = observer(() => {
    const classes = useStyles();
    const store = useContext(StoreContext);
    const { router: { goTo } } = store;
    const [loggingIn, setLoggingIn] = React.useState<boolean>(false)

    useEffect(() => {
        if (store.app.accountStore.account != null) {
            goTo(routes.dashboard, { tab: 'home' })
        }
        else if (localStorage.getItem('billing_cubaAccessToken')) {
            store.app.attemptAutoLogin()
        }
        return () => {
            //this runs when the page unmounts for cleanup actions       
        }
    }, [store.app.accountStore.account, goTo, store.app]);


    useEffect(() => {    
        setLoggingIn(store.app.loggingIn)   
    }, [store.app.loggingIn]);


    const handleLogin = (values: Values) => {
        setLoggingIn(true)
        store.app.doLogin(values.login, values.password)
    }  

    const gotoPasswordReset = () => {
        store.router.goTo(routes.forgotPassword)
    }
    

    return (
    <div>
         <PWAInstallPrompt/>
  
        <div className={classes.version}>Version: {process.env.REPO_BRANCH} {process.env.REACT_APP_BUILD_TAG} </div>
        <Box className={classes.paper} >
            <Container component="main" maxWidth="xs" style={{backgroundColor:'white', borderRadius:5, padding:10, borderColor:'gray'}}>    
            <Card elevation={10} style={{padding:20}}>                    
                    <Avatar className={classes.avatar}>
                        <LockOutlinedIcon />
                    </Avatar>
                    <Typography component="h1" variant="h4" align={'center'} style={{ padding: 12 }}>
                        Ecosmart{/* <EcoIcon/> */} Login
                    </Typography>
                    <Formik
                        initialValues={{ login: "", password: "" }}
                        onSubmit={values => {
                            handleLogin(values)
                        }}>{({ values, handleChange, handleBlur }) => (

                            store.app.loggingIn === true ?
                                <div className={classes.title}>
                                    <CircularProgress
                                        variant="indeterminate"
                                        disableShrink
                                        className={classes.spinner}
                                        classes={{
                                            circle: classes.circle,
                                        }}
                                        size={100}
                                        thickness={4}
                                        //style={{ marginLeft: '40%' }}
                                        style={{ marginTop: '40%' }}

                                    />
                                    <Typography color="textSecondary" gutterBottom>
                                        Signing In...
                            </Typography>
                                </div>
                                :
                                <Form>
                                    <TextField
                                        variant="outlined"
                                        margin="normal"
                                        required
                                        fullWidth
                                        id="email"
                                        label="Account Number"
                                        name="login"
                                        autoComplete="email"
                                        autoFocus
                                        value={values.login}
                                        onChange={handleChange} onBlur={handleBlur}

                                    />
                                    <TextField
                                        variant="outlined"
                                        margin="normal"
                                        required
                                        fullWidth
                                        name="password"
                                        label="Password"
                                        type="password"
                                        id="password"
                                        autoComplete="current-password"
                                        value={values.password}
                                        onChange={handleChange} onBlur={handleBlur}
                                    />
                                  {/*   <FormControlLabel
                                        control={<Checkbox value="remember" color="primary" />}
                                        label="Remember me"
                                    /> */}
                                    {!loggingIn?
                                    <Button
                                        type="submit"
                                        fullWidth
                                        variant="contained"
                                        color="primary"
                                        className={classes.submit}
                                    >
                                        Sign In
                                    </Button>
                                    :null}
                                    <Grid container>
                                        <Grid item xs>
                                            <Link style={{cursor:'pointer'}} variant="body2" onClick={gotoPasswordReset}>
                                                Set Password/Reset Password
                                            </Link>
                                        </Grid>
                                       {/*  <Grid item>
                                            <Link variant="body2" style={{cursor:'pointer'}}>
                                                {"No Account? Sign Up"}
                                            </Link>
                                        </Grid> */}
                                    </Grid>
                                </Form>
                        )}
                    </Formik>     
                    </Card>         
            </Container>
            </Box>
            </div>
    );
})

export default Login