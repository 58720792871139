import { BaseUuidEntity } from "./base/sys$BaseUuidEntity";
import { Contract } from "./billing_Contract";
import { InvoiceVersion, InvoiceType, InvoiceStatus } from "../enums/enums";
import { Line } from "./billing_Line";
import { Plan } from "./billing_Plan";
import { BillingPeriod } from "./billing_BillingPeriod";
export class Invoice extends BaseUuidEntity {
  static NAME = "billing_Invoice";
  contract?: Contract | null;
  invoiceNumber?: string | null;
  invoiceVersion?: InvoiceVersion | null;
  invoiceType?: InvoiceType | null;
  invoiceStatus?: InvoiceStatus | null;
  dueDate?: any | null;
  subTotal?: any | null;
  gst?: any | null;
  total?: any | null;
  directionalTotal?: any | null;
  lines?: Line[] | null;
  plan?: Plan | null;
  washup?: Invoice | null;
  updateTs?: any | null;
  updatedBy?: string | null;
  createTs?: any | null;
  createdBy?: string | null;
  version?: number | null;
  billingPeriod?: BillingPeriod | null;
  deleteTs?: any | null;
  deletedBy?: string | null;
  xeroId?: any | null;
  previousBalance?: any | null;
  emailSent?: any | null;
  authorisationDate?: any | null;
  dueByDate?: any | null;
}
export type InvoiceViewName =
  | "_base"
  | "_local"
  | "_minimal"
  | "invoice-customer-rest-view"
  | "invoice-emailer-view"
  | "invoice-report-view"
  | "invoice-xero-put-view";
export type InvoiceView<V extends InvoiceViewName> = V extends "_base"
  ? Pick<
      Invoice,
      | "id"
      | "invoiceVersion"
      | "invoiceNumber"
      | "invoiceType"
      | "invoiceStatus"
      | "dueDate"
      | "subTotal"
      | "gst"
      | "total"
      | "directionalTotal"
      | "xeroId"
      | "previousBalance"
      | "emailSent"
      | "authorisationDate"
      | "dueByDate"
    >
  : V extends "_local"
  ? Pick<
      Invoice,
      | "id"
      | "invoiceNumber"
      | "invoiceVersion"
      | "invoiceType"
      | "invoiceStatus"
      | "dueDate"
      | "subTotal"
      | "gst"
      | "total"
      | "directionalTotal"
      | "xeroId"
      | "previousBalance"
      | "emailSent"
      | "authorisationDate"
      | "dueByDate"
    >
  : V extends "_minimal"
  ? Pick<Invoice, "id" | "invoiceVersion" | "invoiceNumber">
  : V extends "invoice-customer-rest-view"
  ? Pick<
      Invoice,
      | "id"
      | "invoiceNumber"
      | "invoiceVersion"
      | "invoiceType"
      | "invoiceStatus"
      | "dueDate"
      | "subTotal"
      | "gst"
      | "total"
      | "directionalTotal"
      | "xeroId"
      | "previousBalance"
      | "emailSent"
      | "authorisationDate"
      | "dueByDate"
      | "contract"
      | "lines"
      | "billingPeriod"
    >
  : V extends "invoice-emailer-view"
  ? Pick<
      Invoice,
      | "id"
      | "invoiceNumber"
      | "invoiceVersion"
      | "invoiceType"
      | "invoiceStatus"
      | "dueDate"
      | "subTotal"
      | "gst"
      | "total"
      | "directionalTotal"
      | "xeroId"
      | "previousBalance"
      | "emailSent"
      | "authorisationDate"
      | "dueByDate"
      | "contract"
    >
  : V extends "invoice-report-view"
  ? Pick<
      Invoice,
      | "id"
      | "invoiceNumber"
      | "invoiceVersion"
      | "invoiceType"
      | "invoiceStatus"
      | "dueDate"
      | "subTotal"
      | "gst"
      | "total"
      | "directionalTotal"
      | "xeroId"
      | "previousBalance"
      | "emailSent"
      | "authorisationDate"
      | "dueByDate"
      | "contract"
      | "lines"
      | "billingPeriod"
    >
  : V extends "invoice-xero-put-view"
  ? Pick<
      Invoice,
      | "id"
      | "invoiceNumber"
      | "invoiceVersion"
      | "invoiceType"
      | "invoiceStatus"
      | "dueDate"
      | "subTotal"
      | "gst"
      | "total"
      | "directionalTotal"
      | "xeroId"
      | "previousBalance"
      | "emailSent"
      | "authorisationDate"
      | "dueByDate"
      | "contract"
      | "lines"
    >
  : never;
